import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LineChart } from '@mui/x-charts/LineChart';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,MonthListEng } from '../../utils/common'
import { ApiMemberMyCpd,ApiMemberMyCpdChart,ApiMemberCheckPointByService } from '../../redux/actions/member'
import { ApiGET_SERVICE_TYPE } from '../../redux/actions/master'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { FaRegCircleCheck } from "react-icons/fa6";
import iconAward from '../../assets/images/award-icon.png'
import COEimg from '../../assets/images/COE-img.png'
import iconSearch from '../../assets/images/iconSearch.png'

const Div = styled.div`
   margin-bottom: 50px;
   padding: 10px;
  .css_magin_top_32 {
    margin-top: 32px;
  }
  .box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .box_profile{
    display: flex;
    align-items: center;
    gap:24px;
    .img-profile {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        object-fit: cover;
    }
    .user_img_char_1 {
        border-radius: 50%;
        background-color: #ffffff;
        width: 160px;
        height: 160px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #000000;
        cursor: pointer;
    }
    .text_name_thai{
        color : #101828;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
    }
    .text_name_eng{
        color : #475467;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
    }
  }
  .group_box{
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 73% [col-end]);
    gap: 2%;
  }
`
const DivNewActivity = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #FEC84B;
    background: #FFFCF5;
    padding: 1rem;
    .d-flex {
        display: flex;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`

const DivBoxPoint = styled.div`
    padding: 24px;
    border-radius: 0.75rem;
    border: 1px solid #EAECF0;
    background: #FFFFF;
    .border_header {
        border-bottom: 1px solid rgb(234, 236, 240);
    }
    .css_magin_top_24 {
        margin-top: 24px;
    }
    .css_magin_top_16 {
        margin-top: 16px;
    }
    .header_text_box{
        color: #A91F23;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .text_point{
        color: #101828;
        font-size: 60px;
        font-style: normal;
        font-weight: 600;
    }
    .text_last_activity{
        color: #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }
    .box_last_activity{
        margin-top: 8px;
        margin-bottom: 24px;
        display: flex;
        align-items: top;
        gap:15px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #475467;
        .activity_name{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color : #A91F23;
        }
    }
    .dialog-button {
        text-align: end;
        margin-top: 16px;
    }
`

const DivCheckPoint = styled.div`
    padding: 24px;
    border-radius: 0.75rem;
    border: 1px solid #EAECF0;
    background: #FFFFF;
    .border_header {
        border-bottom: 1px solid rgb(234, 236, 240);
    }
    .css_magin_top_24 {
        margin-top: 24px;
    }
    .css_magin_top_16 {
        margin-top: 16px;
    }
    .header_text_box{
        color: #A91F23;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
    }
    .group_filter {
        display: flex;
        align-items: center;
        gap : 12px;
        .dd_filter{
            min-width : 250px;
        }
        .css_button_filter{
            display: inline-block;
            align-self: flex-end;
        }
    }
    .box_point_service{
        margin-top: 8px;
        margin-bottom: 24px;
        display: flex;
        align-items: top;
        gap:15px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color : #101828;
        .activity_name{
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            color : #101828;
            &.point_pass{
                color : #079455;
            }
        }
    }
`

const DivBoxChart = styled.div`
padding: 24px;
border-radius: 0.75rem;
border: 1px solid #EAECF0;
background: #FFFFF;
.border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
}
.css_magin_top_24 {
    margin-top: 24px;
}
.css_magin_top_16 {
    margin-top: 16px;
}
.header_text_box{
    color: #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}
.main_group_filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.announce_text{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    display: inline-block;
    align-self: flex-end;
    cursor: pointer;
}
.group_filter {
    display: flex;
    align-items: center;
    gap : 12px;
    .dd_filter{
        min-width : 250px;
    }
    .css_button_filter{
        display: inline-block;
        align-self: flex-end;
    }
}
`

const DivNoData = styled.div`
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  .content_data{
    width: 512px;
   
  }
  .div_box_icon{
    text-align: center;
    /*
    img {
      width: 720px;
      height: 405px;
    }
    */
  }
  .head_text{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #101828;
    text-align: center;
    margin-top: 16px;
  }
  .sub_text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    text-align: center;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .group_button{
    display: flex;
    gap : 12px;
    justify-content: center;
  }
`

const CpdPoint = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const [DataInfo, setDataInfo] = useState(null)
  const [isOpenData, setisOpenData] = useState(false)
  const [filterService, setfilterService] = useState('')
  const [filterServiceDate, setfilterServiceDate] = useState('')
  const [ServiceOption, setServiceOption] = useState([])
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 1000,
    }
    dispatch(ApiGET_SERVICE_TYPE(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newStatusOption=  data_info?.data?.map((v) => ({
              text: v?.name_th_th,
              value: v?.id
            }))
            setServiceOption([...newStatusOption])
        }
    })
  }, [dispatch])

  const [isOpenServicePoint, setisOpenServicePoint] = useState(null)

  useEffect(() => {
    const sent_data_to_api = {};
    dispatch(ApiMemberMyCpd(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
            setDataInfo(data_info.data_info);
            setisOpenData(true)
        }
      }
    })

}, [dispatch])

  const [DisplayCheckPoint, setDisplayCheckPoint] = useState(null)
  const onServicePoint = () => {
    const sent_data_to_api = {
        ServiceId : filterService,
        ServiceDate : filterServiceDate
    };
    dispatch(ApiMemberCheckPointByService(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.code == 200){
                setDisplayCheckPoint(data_info.data_info);
                setisOpenServicePoint(true)
            }
        }
    })
  }
  const onGotoCpdPointHistory = () => {
    navigate('/Member/CpdPointHistory')
  }

  //---- ST LineChart
  /*
  const labels = MonthListEng();
  const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'คะแนนรวมที่ใช้ได้',
            data: [120,110,100,90,80,70,60,50,40,30,20,0],
            borderColor: 'rgba(169, 31, 35, 1)',
            backgroundColor: 'rgba(169, 31, 35, 0.1)',
        }
    ]
  }
  */
  const options = {
    plugins : {
        legend : false
    },
    scales : {
        x: {
            grid : {
                display : false
            }
        }
    }
  };
  const [isOpenChart, setisOpenChart] = useState(false)
  const [DataChart, setDataChart] = useState(null)

  useEffect(() => {
    const sent_data_to_api = {};
    dispatch(ApiMemberMyCpdChart(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
            let ArrLebels = [];
            let ArrData = [];
            const AllData = data_info.data_info;
            for (const item_data of AllData) {
                ArrLebels.push(item_data.display_labels);
                ArrData.push(item_data.point_active);
            }
            if(ArrLebels.length > 0){
                setDataChart({
                    labels : ArrLebels,
                    datasets: [
                        {
                            fill: true,
                            label: 'คะแนนรวมที่ใช้ได้',
                            data: ArrData,
                            borderColor: 'rgba(169, 31, 35, 1)',
                            backgroundColor: 'rgba(169, 31, 35, 0.1)',
                        }
                    ]
                });
                setisOpenChart(true)
            }
            
        }

        
      }
    })

  }, [dispatch])
  //---- ED LineChart

  const onGotoSelfDeclaration = () => {
    navigate('/Activity/SelfDeclaration')
  }

  const onGotoEvents = () => {
    navigate('/Activity/Events')
  }

  return (
    <Div>
        <div className="box_header">
            <div>
                <div className="content-title">
                    My CPD
                </div>
                <div className="content-title-text">
                    ตรวจสอบคะแนนและคำนวนการนำคะแนนไปใช้
                </div>
            </div>
        </div>
        <div className="css_magin_top_32"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_32"></div>
        <div className="box_profile">
            <div>
                {user?.url_profile ? (
                <img className="img-profile" src={user?.url_profile} />
              ) : (
                <div className="user_img_char_1" >
                    <h3>{user?.first_name ? user?.first_name?.substring(0, 1) : 'A'}</h3>
                </div>
              )}
            </div>
            <div>
                <div className="text_name_thai">
                    {user?.full_name ? user?.full_name : '-'}
                </div>
                <div className="text_name_eng">
                    {user?.fullname_eng ? user?.fullname_eng : '-'}
                </div>
            </div>
        </div>
        {isOpenData == true && DataInfo?.total_point_active ? ( 
            <div>        
                <div className="css_magin_top_32"></div>
                <DivNewActivity>
                    <div className="d-flex">
                        <div className="icon-warning-container">
                            <div className="box_icon" >
                                <FaRegCircleCheck size={25} />
                            </div>                    
                        </div>
                        <div className="warning-message-container">
                            <div className="headline-warning">
                                คุณได้รับคะแนนใหม่ {DataInfo?.last_ativity_point[0]?.user_point} คะแนนจาก {DataInfo?.last_ativity_point[0]?.ref_activity} : {DataInfo?.last_ativity_point[0]?.activity_name}
                            </div>
                            <div>{DataInfo?.last_ativity_point[0]?.created_at ? DateTimeHHmmDisplay(DataInfo?.last_ativity_point[0]?.created_at) : '-'}</div>
                            <div className="detail_click" onClick={onGotoCpdPointHistory}>ดูรายละเอียด</div>
                        </div>
                    </div>            
                </DivNewActivity>
                <div className="css_magin_top_32"></div>
                <div className="group_box">
                    <DivBoxPoint>
                        <div className="header_text_box">
                            คะแนนรวมที่ใช้ได้วันนี้
                        </div>
                        <div className="css_magin_top_24"></div>
                        <div className="text_point">
                            {DataInfo?.total_point_active}
                        </div>
                        <div className="css_magin_top_16"></div>
                        <div className="text_last_activity">
                            รายการล่าสุด
                        </div>
                        <div className="box_last_activity">
                            <div>
                                <img src={iconAward}  />
                            </div>
                            <div>
                                <div>
                                {DataInfo?.last_ativity_point[0]?.user_point} คะแนน {DataInfo?.last_ativity_point[0]?.created_at ? DateTimeHHmmDisplay(DataInfo?.last_ativity_point[0]?.created_at) : '-'}
                                </div>
                                <div>
                                จาก {DataInfo?.last_ativity_point[0]?.ref_activity} <span className="activity_name">{DataInfo?.last_ativity_point[0]?.activity_name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="border_header"></div>
                        <div className="dialog-button">
                            <ButtonOutline onClick={onGotoCpdPointHistory}>
                            ดูประวัติการได้คะแนน
                            </ButtonOutline>
                        </div>
                    </DivBoxPoint>
                    <DivCheckPoint>
                        <div className="header_text_box">
                            ตรวจสอบคะแนนเพื่อนำไปใช้
                        </div>
                        <div className="css_magin_top_24"></div>
                        <div className="group_filter">
                            <div >
                                <Field className="field" label="เลือกการนำคะแนนไปใช้">
                                <Dropdown
                                    id={`dd_filterUser`}
                                    className="dd_filter"
                                    value={filterService}
                                    optionList={ServiceOption}
                                    onChange={(v) => setfilterService(v)}
                                    placeHolder={'เลือก'}
                                />
                                </Field>
                            </div>
                            <div >
                                <Field className="field" label="เลือกวันที่ต้องการยื่น">
                                    <Date
                                        className="dd_filter"
                                        value={filterServiceDate}
                                        onChange={(v) => setfilterServiceDate(v)}
                                    />
                                </Field>
                            </div>
                            <div className="css_button_filter">
                                <Button  onClick={onServicePoint}>
                                    ตรวจสอบ
                                </Button>
                            </div>
                        </div>
                        <div className="css_magin_top_24"></div>
                        {isOpenServicePoint == true && ( 
                            <div className="box_point_service">
                                <div>
                                    <img src={COEimg}  />
                                </div>
                                <div>
                                    <div>
                                        {DisplayCheckPoint?.display_text1 ? DisplayCheckPoint?.display_text1 : '-'}
                                    </div>
                                    <div className={`activity_name ${DisplayCheckPoint?.is_point_check_pass ? 'point_pass' : ''}`}>
                                        {DisplayCheckPoint?.display_text2 ? DisplayCheckPoint?.display_text2 : '-'}
                                    </div>
                                    <div>
                                        {DisplayCheckPoint?.display_text3 ? DisplayCheckPoint?.display_text3 : '-'}
                                    </div>
                                </div>
                            </div>
                        )}
                    </DivCheckPoint>
                </div>
                <div className="css_magin_top_32"></div>
                <DivBoxChart>
                    <div className="header_text_box">
                        ดูคะแนนของคุณแบบเรียลไทม์ 
                    </div>
                    <div className="css_magin_top_24"></div>
                    <div className="announce_text">
                        ดูเกณฑ์การใช้คะแนน
                    </div>
                    <div className="css_magin_top_24"></div>
                    {/* ST LineChart */}
                    {isOpenChart == true && ( 
                        <div >
                            <Line data={DataChart} options={options} ></Line>
                        </div>
                    )} 
                    {/* ED LineChart */}        
                </DivBoxChart>
            </div>  
        ) : (
            <div>
                <DivNoData>
                    <div className="content_data">
                        <div className="div_box_icon">
                            <img src={iconSearch} />
                        </div>  
                        <div className="head_text">
                            คุณยังไม่มีประวัติคะแนน CPD
                        </div>
                        <div className="css_magin_top_24"></div>
                        <div className="sub_text">
                            สามารถเริ่มเก็บคะแนนได้ที่เมนู
                        </div>
                        <div className="sub_text">
                            Self-Declaration
                        </div>
                        <div className="sub_text">
                            เข้าร่วมกิจกรรมองค์กรแม่ข่าย
                        </div>
                        <div className="sub_text">
                            หรืออบรมผ่าน E-learning
                        </div>
                        <div className="css_magin_top_24"></div>
                        <div className="group_button">
                            <ButtonOutline  onClick={onGotoSelfDeclaration}>
                                Self Declaration
                            </ButtonOutline>
                            <Button  onClick={onGotoEvents}>
                                กิจกรรมองค์กรแม่ข่าย
                            </Button>
                        </div>
                    </div>
                </DivNoData>
            </div>
        )
        }          
    </Div>
  )
}

export default CpdPoint
