import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_elearning,
    POST_elearning,
    UPDATE_elearning
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const Api_Post_OrgElearning = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/MemberElearning`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_Post_OrgElearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_Post_OrgElearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_org_num_status_elearning = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/member_num_status_elearning`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_org_num_status_elearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_org_num_status_elearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_org_vw_elearning = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_elearning.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }

      if(filter.status_id){
        params = {...params,'status_id': filter.status_id}
      }

      if(filter.name){
        params = {...params,'topic_name[$like]': `%${filter.name}%`}
      }

      if(filter.user){
        params = {...params,'created_by': filter.user}
      }

      if(filter.status_exam){
        params = {...params,'status_exam': filter.status_exam}
      }

      if(filter.date){
        params = {...params,'createdAt[$gte]': `${filter.date} 00:00:00` , 'createdAt[$lte]': `${filter.date} 23:59:59`}
      }

      const data_result = await getAPI({
        url: '/api/elearning/member_vw_elearning?$sort[id]=-1',
        headers: headers,
        params,
      })

      return { type: 'Api_org_vw_elearning_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_org_vw_elearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiUpdateOrgElearning = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/elearning/MemberElearning/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'ApiUpdateOrgElearning_SUCCESS', data_info: data_result }

    } catch (err) {

      return { type: 'ApiUpdateOrgElearning_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiOrgElearning_profile = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/member/MemberElearningProfile`,
        data,
        headers: headers,
      })
      
      return { type: 'ApiOrgElearning_profile_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'ApiOrgElearning_profile_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_get_OrgElearningExam = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_elearning.REQUEST })

      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
        'status_id': 1,
      }

      if(filter.elearning_id){
        params = {...params,'elearning_id': filter.elearning_id}
      }

      const data_result = await getAPI({
        url: '/api/elearning/MemberElearningExam?$sort[id]=1',
        headers: headers,
        params,
      })

      return { type: 'Api_get_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_get_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_Post_OrgElearningExam = (data) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: POST_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      
      const data_result = await  postAPI({
        url: `/api/elearning/MemberElearningExam`,
        data,
        headers: headers,
      })
      
      return { type: 'Api_Post_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {
      return { type: 'Api_Post_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const Api_Update_OrgElearningExam = (id, data) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: UPDATE_elearning.REQUEST })
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      const data_result = await patchAPI({
        url: `/api/elearning/MemberElearningExam/${id}`,
        data,
        headers: headers,
      })
  
      return { type: 'Api_Update_OrgElearningExam_SUCCESS', data_info: data_result }

    } catch (err) {

      return { type: 'Api_Update_OrgElearningExam_ERROR' }

    } finally {
      dispatch(hideLoading())
    }
}

export const ApiUpdateStatusElearning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/MemberUpdateStatusElearning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiUpdateStatusElearning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiUpdateStatusElearning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetElearningData = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const user_info = JSON.parse(localStorage.getItem('user_info'));
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let data_api = {
      ...data,
      organizations_id : user_info?.organizations_id ? user_info?.organizations_id : null
    }
    
    const data_result = await  postAPI({
      url: `/api/member/GetElearningData`,
      data : data_api,
      headers: headers,
    })
    
    return { type: 'ApiGetElearningData_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetElearningData_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const Api_Post_Elearning_Register = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/elearning_register`,
      data,
      headers: headers,
    })
    
    return { type: 'Api_Post_Elearning_Register_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'Api_Post_Elearning_Register_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const Api_Update_Elearning_Register = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    const data_result = await patchAPI({
      url: `/api/member/elearning_register/${id}`,
      data,
      headers: headers,
    })

    return { type: 'Api_Update_Elearning_Register_SUCCESS', data_info: data_result }

  } catch (err) {

    return { type: 'Api_Update_Elearning_Register_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberEndElearning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberEndElearning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberEndElearning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberEndElearning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberEndExam = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberEndExam`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberEndExam_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberEndExam_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberElearningTimeDuration = (data) => async (dispatch) => {
  try {
    
    //dispatch(showLoading())
    //dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberElearningTimeDuration`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberElearningTimeDuration_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberElearningTimeDuration_ERROR' }

  } finally {
    return { type: 'ApiMemberElearningTimeDuration_SUCCESS' }
    //dispatch(hideLoading())
  }
}

export const ApiMemberElearningCondition = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberElearningCondition`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberElearningCondition_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberElearningCondition_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberCheckElearning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberCheckElearning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberCheckElearning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberCheckElearning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiMemberCancleElearning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/member/MemberCancleElearning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberCancleElearning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberCancleElearning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}


export const ApiEleaningExamEngineer = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/MemberEleaningExamEngineer`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiEleaningExamEngineer_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiEleaningExamEngineer_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiOrgEleaningExamList = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/MemberEleaningExamList`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiOrgEleaningExamList_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiOrgEleaningExamList_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiAddExamFromElerning = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/MemberAddExamFromElerning`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiAddExamFromElerning_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiAddExamFromElerning_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiElearning_profile = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_elearning.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/elearning/MemberElearning_profile`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiElearning_profile_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiElearning_profile_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}
