import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser } from '../../utils/common'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import TabAppealForm from '../../pages/Appeal/TabAppealForm'
import TabAppealHistory from '../../pages/Appeal/TabAppealHistory'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'

const Div = styled.div`
margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }

  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .button_CreateModal {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
`



const AppealInfo = () => {
  const dispatch = useDispatch()

  const [tabactive, setTabactive] = useState('TabAppealForm')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'ร่างคำร้อง',
        value: 'TabAppealForm',
        active_status: true,
    },
    {
        name: 'ประวัติคำร้อง',
        value: 'TabAppealHistory',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)

      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  return (
    <Div>
      <header>
        <div className="box_header">
            <div>
                <div className="content-title">
                    คำร้องอุทธรณ์คะแนน CPD
                </div>
                <div className="content-title-text">
                    สำหรับสมาชิกเขียนแบบฟอร์มคำร้องเกี่ยวกับคะแนน CPD
                </div>
            </div>            
        </div>
        <div className="border_header"></div>
        <div className="css_magin_top_24"></div>
        {/* TAB */}
        <DivTab>
            {menuTabList?.map((item, index) => (
                <button
                id={'this_name-' + item.value}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                onClick={() => handleClickTaskMenuTab(item)}
                >
                    {item.name}                 
                </button>
            ))}
        </DivTab>
        {/* ค้นหา */}
        <div className="css_magin_top_24"></div>
        {tabactive == 'TabAppealForm' && (
          <div>
              <TabAppealForm />
          </div>
        )}
        {tabactive == 'TabAppealHistory' && (
          <div>
              <TabAppealHistory />
          </div>
        )}
        
      </header>

    </Div>
  )
}

export default AppealInfo
