export const selectLoading = (state) => state.loading

export const selectAlert = (state) => state.alert

export const selectMaster = (state) => state.master.masterReducer

/*
export const selectCmsByType = (type) => (state) => state.cms.content[type] || {}
export const selectCmsContentType = (type) => (state) => state.cms.contentType.find((v) => v.apiID === type) || {}
export const selectCmsContentTypeByUid = (uid) => (state) => state.cms.contentType.find((v) => v.uid === uid) || {}

export const selectMaterial = (state) => state.material.materialReducer
export const selectProduct = (state) => state.product.productReducer

export const selectUser = (state) => state.userlist.usersReducer
export const selectAccessRole = (state) => state.accessRole.accessRoleReducer

export const selectApplicationForm = (state) => state.applicationForm.applicationFormReducer

export const selectMaster = (state) => state.master.masterReducer
export const selectFormPassword = (state) => state.resetpassword.resetPasswordReducer
export const selectSummaryTask = (state) => state.summaryTask.summaryTaskReducer
export const selectDepartMent = (state) => state.department.departmentReducer
export const selectScopeOfWork = (state) => state.scopeOfWork.scopeOfWorkReducer
export const selectJobPosition = (state) => state.jobPosition.jobPositionReducer
export const selectTaskType = (state) => state.taskType.taskTypeReducer

export const selectEmployee = (state) => state.employee.employeeReducer

export const selectCompanyProfile = (state) => state.company.companyReducer
export const selectTaskEmployee = (state) => state.taskEmployee.taskEmployeeReducer
export const selectProjectTask = (state) => state.projectTask.projectTaskReducer

export const selectOrganizationChart = (state) => state.organizationChart.organizationChartReducer

export const selectDailySummary = (state) => state.dailysummary.dailySummaryReducer

export const selectNotificatons = (state) => state.notifications.notificationsReducer

export const selectProjectDashboard = (state) => state.projectDashboard.projectDashboardReducer
export const selectEmployeeDashboard = (state) => state.employeeDashboard.employeeDashboardReducer

export const selectSharedService = (state) => state.sharedService.sharedServiceReducer
export const selectSharedServiceEmployee = (state) => state.shareServiceEmployee.sharedServiceEmployeeReducer
export const selectDocumentCategory = (state) => state.documentCategory.documentCategoryReducer
export const selectDocumentTemplate = (state) => state.documentTemplate.documentTemplateReducer

export const selectDocument = (state) => state.document.documentReducer

export const selectEmployeeActivity = (state) => state.employeeActivity.employeeActivityReducer

export const selectEmployeeProfile = (state) => state.employeeProfile.employeeProfileReducer
export const selectOfmDashboard = (state) => state.ofmDashboard.ofmDashboardReducer
export const selectOrgTeam = (state) => state.OrganizationTeam.organizationTeamReducer

export const selectKPI = (state) => state.kpiManagement.kpiManagementReducer

export const selectOperationProcess = (state) => state.operationProcess.operationProcessReducer
*/
