import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay } from '../../utils/common'
import { ApiOrgElearning_profile,ApiMemberEndElearning,ApiMemberElearningTimeDuration,ApiMemberElearningCondition,ApiMemberCheckElearning,ApiMemberCancleElearning } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import Modal from '../../components/common/Modal'
import DialogFail from '../../components/dialog/DialogFail'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import iconNext from '../../assets/images/icon-next-white.svg'
import iconBack from '../../assets/images/icon-back.svg'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconFile from '../../assets/images/pdf-icon.png'
import crossIcon from '../../assets/images/crossIcon.png'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FiBook } from "react-icons/fi";
import { AiOutlinePushpin } from "react-icons/ai";
import { LiaAwardSolid } from "react-icons/lia";
import { IoTimeOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoMdCheckboxOutline } from "react-icons/io";
import { IoMdClose } from 'react-icons/io'
import { LuFileCheck } from "react-icons/lu";
import { FaListUl } from "react-icons/fa";
import { MdOutlineCalculate } from "react-icons/md";
import { GrStatusGoodSmall } from 'react-icons/gr'
import { FaArrowRight } from "react-icons/fa6";
import HappyIcon from '../../assets/images/Haapy-icon.png'
import NertralIcon from '../../assets/images/Nertral-icon.png'
import warningIcon from '../../assets/images/warning-icon.png'

const Div = styled.div`
  margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .main_box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title-feature {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    color: #101828;
  }
  .box_header_right{
    color : #475467;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    
    .fill_text{
      color : #475467;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    .status_learning{
      display: flex;
      gap: 10px;
      margin-top: 12px;
    }
    .box_user_status{
      .status-badge {
        width: fit-content;
        padding: 2px 6px;
        text-align: center;
        font-weight: 500;
        border-radius: 16px;
        color: #475467;
        background: #f5f5f4;
        border: 1px #e7e5e4 solid;
        .status {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #475467;    
        }
      }
      .margin_badge{
        margin-top: 5px;
      }
    }
  }
  .css_magin_top_32 {
    margin-top: 32px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  
`
const DivCondition = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
padding: 24px;
.other_box{
  display: grid;
  grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
  gap: 2%;
  align-items: start;
  /*margin-top: 12px;*/
}
.other_box_sub{
  display: grid;
  grid-template-columns: repeat(2, 30% [col-start] 65% [col-end]);
  gap: 2%;
  align-items: start;
  /*margin-top: 12px;*/
}
.content-topic-icon{
  display: flex;
  align-items: center;
  /*line-height: 0px;*/
  gap: 10px;
  color : #667085;
}
.css_icon{
  display: flex;
  align-items: center;
}
.text_normal{
  color : #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.text_input{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.css_magin_top_12 {
  margin-top: 12px;
}
`
const DivFullContent = styled.div`
.css_magin_top_16{
  margin-top: 16px;
}
.css_magin_top_24{
  margin-top: 16px;
}
.status-badge {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #A91F23;
    background: #FEF3F2;
    border: 1px #FECDCA solid;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .status-badge-engineer {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #A91F23;
    background: #FFF;
    border: 1px #FECDCA solid;
    display: flex;
    gap: 2px;
    align-items: center;
  }
  
  .topic_css{
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
  }
  .detail_css{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .cover_img{
    text-align: center;
    img {
        width: 1600px;
        height: 480px;
    }
  }
  .css_box_date{
    display: flex;
    gap: 48px;
    .topic{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #A91F23;
    }
    .detail{
        margin-top : 12px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: #101828;
    }
  }

`

import ReactQuill from 'react-quill'
import ReactPlayer from 'react-player'
const DivDataContent = styled.div`
  
  /*padding: 20px 250px;*/
  
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  
  .content_data{
    
    width: 720px;
    
  }
  .content_margin_top_14{
    margin-top: 14px;
  }
  .ql-container {
    min-height: 45px;
    font-family: inherit;
  }
  .ql-tooltip{
    display : none;
  }
  .ql-editor{
    padding: 0px 0px;
    font-size: 18px;
    h2{
      font-size: 20px;
    }
  }
  .div_box_type_content_text{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 14px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .div_box_type_content_vdo{
    display: flex;
    justify-content: center;
  }
  .div_box_type_content_vdo_sub_content{
    margin-top: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .div_box_type_content_img{
    display: flex;
    justify-content: center;
    padding: 14px 0px;
  }
  .div_box_type_content_img_head_content{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .div_box_type_content_img_sub_content{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
  }
  .div_box_type_content_img_url{
    text-align: center;
    img {
      width: 720px;
      height: 405px;
    }
  }
  .div_box_type_content_pdf{
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 0px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    .icon-wrapper{
      width: 32px;
    }
    .detail-wrapper{
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .div_box_type_content_url{
    .head_content{
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      color : #101828
    }
    .url_list{
      margin-bottom: 16px;
      a {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        color : #0086C9;
        text-decoration-line: underline;
      }
    }
  }
  .button_end_content{
    display: flex;
    justify-content: center;
  }
  .button_disable{
    color: #FFFFFF;
    background-color: #D0D5DD;
    border: 1px solid #D0D5DD;
  }
`
const DivModalConfirm = styled.div`
  padding: 24px 46px;
  /*min-width: 500px;*/
  width: 600px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color : #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .link_goto{
    /*margin-bottom: 16px;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color : #0086C9;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

const DivModalWarningElearning = styled.div`
  padding: 24px 46px;
  /*min-width: 500px;*/
  width: 640px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color: #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .link_goto{
    /*margin-bottom: 16px;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color : #0086C9;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styled_mui } from '@mui/material/styles'
const BorderLinearProgress = styled_mui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#c8102e' : '#c8102e',
  },
}))
const DivProgress = styled.div`
  display: flex;
  gap: 10px;
  margin: 16px 0px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color : #667085;
  width: 100%;
  .headline{
    color : #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  .progress_text{
    width: 480px;
  }
  .progress_display{
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .linear_progress{
    width: 100%;
    margin-top: 5px;
  }
`

const ElearningInfo = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()
  const { id } = useParams()

  const [ModalWarning, setModalWarning] = useState(false);
  const [ModalConfirmCancle, setModalConfirmCancle] = useState(false)
  const [ElearningStill, setElearningStill] = useState({
    id : null,
    topic_name : '',
    sub_detail : ''
  })

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)

  const [ModalConfirmSuccess, setModalConfirmSuccess] = useState(false)
  const [DataInfoReturn, setDataInfoReturn] = useState({
    point : 0
  })

  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failModal, setFailModal] = useState(false)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [successModal, setSuccessModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)

  const [TotleTimePage, setTotleTimePage] = useState(0);
  const [timeSpentOnPage, setTimeSpentOnPage] = useState(0);
  const [timeSpentNotActive, settimeSpentNotActive] = useState(0);

  const [TotleTimeVDO, setTotleTimeVDO] = useState(0);
  const [ActiveButton, setActiveButton] = useState(false);
  const [NumVDO, setNumVDO] = useState(0);

  useEffect(() => {
    const data_to_api_check = {
      elearning_id : id
    };
    dispatch(ApiMemberCheckElearning(data_to_api_check)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          const sent_data_to_api = {
              elearning_id : id,
          };
          dispatch(ApiOrgElearning_profile(sent_data_to_api)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if(data_info.data_info){
                setTotleTimePage(data_info.data_info.time_duration)
                setTotleTimeVDO(data_info.data_info.time_duration_vdo);

                localStorage.setItem('elearning_register_id', data_info.data_info.elearning_register_id);
                localStorage.setItem('TotleTimeVDO', data_info.data_info.time_duration_vdo);
                
                setDataInfo(data_info.data_info);
                if(data_info.data_info?.data_content){
                  const num_vdo = data_info.data_info?.data_content ? data_info.data_info?.data_content.filter((v) => v.type_content == 'vdo').length : 0;
                  setNumVDO(num_vdo);
                  if(num_vdo > 0){
                    if(data_info.data_info.time_duration_vdo >= data_info.data_info.elearning_duration_second){
                      setActiveButton(true)
                    }else{
                      setActiveButton(false)
                    }
                  }else{
                    setActiveButton(true)
                  }
                }
                setisOpenData(true)
              }
            }
          })
        }else if(data_info.code == 300){
          setElearningStill({
            id : data_info.elearning_id,
            topic_name : data_info.topic_name,
            sub_detail : data_info.sub_detail,
          })
          setModalWarning(true)
        }
      }
    })
}, [dispatch])

  //--- ST จับเวลา Onpage
  useEffect(() => {
    if(DataInfo){
      const intervalId = setInterval(() => {
        if(!document.hidden){ //---- ส่ง API
          /* //--- ยกเลิกการส่ง API เหตุเพราะจะ Monitor ว่าเป็นเหตุให้ 500 หรือไม่
          const data_to_api = {
            elearning_register_id : DataInfo.elearning_register_id,
            time_duration : 1
          };
          dispatch(ApiMemberElearningTimeDuration(data_to_api));
          */
          setTimeSpentOnPage(prevTime => prevTime + 1);
        }else{ //----- ปิด page จะส่ง API
          settimeSpentNotActive(prevTime => prevTime + 1);
        }
        setTotleTimePage(prevTime => prevTime + 1);
      }, 1000); // every 1 second
      return () => clearInterval(intervalId);
    }

    
  }, [dispatch,DataInfo]);
  //--- ED จับเวลา Onpage

const ProcessAPI = () => {
  const data_to_api = {
    elearning_id : id,
    TotleTimeVDO : TotleTimeVDO
  };
  dispatch(ApiMemberElearningCondition(data_to_api)).then(({ type,data_info }) => {
    if(type.endsWith('_SUCCESS')){
      if(data_info.code == 200){

        dispatch(ApiMemberEndElearning(data_to_api)).then(({ type: type_sub,data_info : data_info_sub }) => {
          if(type_sub.endsWith('_SUCCESS')){
            if(data_info_sub.code == 200){
              if(data_info_sub?.point){
                setDataInfoReturn({
                  point : data_info_sub?.point
                })
                setModalConfirmSuccess(true);
              }else{
                navigate(data_info_sub.page_go_to)
              }        
            }
          }
        })

      }else if(data_info.code == 301){
        setFailMessageModal({
          headline: 'ท่านได้ยกเลิกการเรียนรู้นี้แล้ว',
          message: '',
        })
        setFailModal(true)
      }else{
        setFailMessageModal({
          headline: 'ไม่สามารถจบการเรียนรู้ได้',
          message: 'เนื่องจากดู VDO ไม่ผ่านเงื่อนไข',
        })
        setFailModal(true)
      }
    }else{
      setFailMessageModal({
        headline: 'internal server error',
        message: '',
      })
      setFailModal(true)
    }
  })
  

  
}
const gotoMyCPD = () => {
  navigate('/Member/CpdPoint')
}

const gotoPageBack = () => {
  navigate('/Activity/Elearning')
}


useEffect(() => {
  if(DataInfo){
    if(document.hidden){
      const data_to_api = {
        elearning_register_id : DataInfo.elearning_register_id,
        is_doc_hidden : true,
        time_duration_vdo : TotleTimeVDO
      };
      dispatch(ApiMemberElearningTimeDuration(data_to_api));
    }
  }
}, [dispatch,DataInfo,document.hidden]);

//---- ST กรณีมีการปิด PAGE
useEffect(() => {
  window.addEventListener('beforeunload', alertUser)
  window.addEventListener('unload', handleTabClosing)
  return () => {
      window.removeEventListener('beforeunload', alertUser)
      window.removeEventListener('unload', handleTabClosing)
  }
})

const handleTabClosing = () => {
  console.log('--- handleTabClosing ----')
  const data_to_api = {
    elearning_register_id : DataInfo.elearning_register_id,
    is_doc_hidden : true,
    time_duration_vdo : TotleTimeVDO
  };
  dispatch(ApiMemberElearningTimeDuration(data_to_api));
  removePlayerFromGame()
}

const alertUser = () => {
  console.log('alertUseralertUseralertUseralertUser');
}
//---- ED กรณีมีการปิด PAGE


const handleProgressVDO = (secs) => {
  if(secs > 0){
    if(TotleTimeVDO + 1 >= DataInfo.elearning_duration_second){
      setActiveButton(true)
    }
    setTotleTimeVDO(prevTime => prevTime + 1);
    localStorage.setItem('TotleTimeVDO', TotleTimeVDO + 1);
    /* //--- ยกเลิกการส่ง API เหตุเพราะจะ Monitor ว่าเป็นเหตุให้ 500 หรือไม่
    const data_to_api = {
      elearning_register_id : DataInfo.elearning_register_id,
      time_duration_vdo : 1
    };
    dispatch(ApiMemberElearningTimeDuration(data_to_api));
    */
  }

};

const GotoElearningInfo = () => {
  if(ElearningStill.id){
    //navigate('/Activity/ElearningInfo/' + ElearningStill.id)

    window.location.href = '/Activity/ElearningInfo/' + ElearningStill.id;
  }
}

const IsModalConfirmCancle = () => {
  setModalWarning(false);
  setModalConfirmCancle(true);
}
const ProcessMemberCancleElearning = () => {
  setModalConfirmCancle(false);
  const data_to_api_check = {
    elearning_id : ElearningStill.id
  };
  dispatch(ApiMemberCancleElearning(data_to_api_check)).then(({ type,data_info }) => {
    if(type.endsWith('_SUCCESS')){
      if(data_info.code == 200){
        setSuccessMessageModal({
          headline: 'ทำรายการสำเร็จ',
          message: 'สามารถเริ่มการเรียนรู้บทเรียนอื่นๆ ได้',
        })
        setSuccessModal(true)
      }else{
        setFailMessageModal({
          headline: 'ไม่สามารถยกเลิกบทความนี้ได้',
          message: '',
        })
        setFailModal(true)
      }
    }else{
      setFailMessageModal({
        headline: 'internal server error',
        message: '',
      })
      setFailModal(true)
    }
  })
 }




  return (
    <Div>
      {isOpenData == true && ( 
        <div>
          <div className="box_header">
              <div>
                  <div className="content-title-feature">
                      CPD E-Learning
                  </div>
                  <div className="content-title">
                      {DataInfo.topic_name}
                  </div>
              </div>
          </div>
          <div className="css_magin_top_32"></div>
          <div className="border_header"></div>
          <div className="css_magin_top_32"></div>
          <DivCondition>
              <div className="other_box">
                <div >
                  <div className="other_box_sub">
                    <div className="content-topic-icon">                                
                        <div className="css_icon">
                        <AiOutlinePushpin size={20}   />
                        </div>
                        <div className="text_normal">แขนงความรู้</div>
                    </div>
                    {DataInfo.field_study_data ? ( 
                        <div className="text_input">
                              { DataInfo.field_study_data?.map((item, index) => (
                                  <span>
                                      {index > 0 && (   
                                          <span>,</span>
                                      )}
                                      {item.knowledge_th + '-' + item.field_study_th}
                                      
                                  </span>
                              ))}                            
                        </div> 
                      ) : (
                        <div className="text_input">-</div> 
                      )
                    }                         
                  </div>
                  <div className="css_magin_top_12"></div>
                  <div className="other_box_sub">
                    <div className="content-topic-icon">                                
                          <div className="css_icon">
                          <IoTimeOutline size={20}   />
                          </div>
                          <div className="text_normal">ระยะเวลาอ่าน</div>
                    </div>
                    <div className="text_input">{DataInfo.time_read_display}</div>
                  </div>
                </div>
                <div >
                  <div className="other_box_sub">
                    <div className="content-topic-icon">                                
                        <div className="css_icon">
                        <LuFileCheck size={20}   />
                        </div>
                        <div className="text_normal">แบบทดสอบ</div>
                    </div>
                    <div className="text_input">{DataInfo?.status_exam_display}</div>
                  </div>
                  <div className="css_magin_top_12"></div>
                  <div className="other_box_sub">
                      <div className="content-topic-icon">                               
                          <div className="css_icon">
                              <LiaAwardSolid size={20}   />
                          </div>
                          <div className="text_normal">คะแนนที่จะได้รับ</div>
                      </div>
                      {DataInfo?.exam_data ? ( 
                          <div className="text_input">สอบผ่าน =  {DataInfo.point_exam_pass} คะแนน,  สอบไม่ผ่าน = {DataInfo.point} คะแนน</div>
                        ) : (
                          <div className="text_input">
                            {DataInfo.point} คะแนน
                          </div>
                        )
                      }
                  </div> 
                </div>
              </div>
          </DivCondition>
          <div className="css_magin_top_32"></div>
          <DivFullContent>
            {
              /*
             <div className="topic_css">
                  {DataInfo.topic_name}
              </div>
              <div className="css_magin_top_24"></div>
              <div className="detail_css">
                  {DataInfo.detail}
              </div>
              */
            }

            {
              /*
              {DataInfo.cover_img && ( 
                <div>
                    <div className="css_magin_top_24"></div>
                    <div className="cover_img">
                        <img src={DataInfo.cover_img } />
                    </div>
                </div>
              )}
              */
            }
            <div className="css_magin_top_24"></div>
            <div className="detail_css">
                {DataInfo.detail}
            </div>
            <div className="css_magin_top_24"></div>
            <div className="main_box_header">
              <div className="css_box_date">
                <div>
                    <div className="topic">วันที่เผยแพร่</div>
                    <div className="detail">
                        {DataInfo.approve_at ? DateDisplay(DataInfo.approve_at) : '-'}
                    </div>
                </div>
                <div>
                    <div className="topic">วันที่อัปเดท</div>
                    <div className="detail">
                        {DataInfo.updated_at ? DateDisplay(DataInfo.updated_at) : '-'}
                    </div>
                </div>
                <div>
                    <div className="topic">เขียนโดย</div>
                    <div className="detail">
                        {DataInfo.organizations_name}
                    </div>
                </div>
              </div>
              <div className="box_header_right">
                <div>เข้าชมล่าสุดเมื่อ : <span className="fill_text">{DataInfo.elearning_user_last_date ? DateTimeHHmmDisplay(DataInfo.elearning_user_last_date) : '-'}</span></div>
                <div className="status_learning">
                  <div>
                    สถานะการเรียนรู้
                  </div>
                  <div className="box_user_status">
                    <div
                      className="status-badge"
                      style={{
                        color: DataInfo.elearning_user_badge_color?.color,
                        borderColor: DataInfo.elearning_user_badge_color?.border,
                        backgroundColor: DataInfo.elearning_user_badge_color?.background,
                      }}
                    >
                      <GrStatusGoodSmall 
                        className="status"
                        style={{
                          color: DataInfo.elearning_user_badge_color?.color,
                        }}
                      />
                      {DataInfo.elearning_user_status}
                    </div>
                    {DataInfo?.status_exam == 1 && (    
                      <div
                        className="status-badge margin_badge"
                        style={{
                          color: DataInfo.elearning_exam_status_badge_color?.color,
                          borderColor: DataInfo.elearning_exam_status_badge_color?.border,
                          backgroundColor: DataInfo.elearning_exam_status_badge_color?.background,
                        }}
                      >
                        <GrStatusGoodSmall 
                          className="status"
                          style={{
                            color: DataInfo.elearning_exam_status_badge_color?.color,
                          }}
                        />
                        {DataInfo.elearning_exam_status}
                      </div>
                    )}  
                  </div>
                </div>
              </div>
            </div>

            <div>
              <DivDataContent >
                <div className="content_data">                       
                  {DataInfo?.data_content?.map((item, index) => (
                    <div>
                      {item.type_content == 'text' && (
                        <div>
                          <div>
                            <ReactQuill  
                            value={item.content} 
                            readOnly={true} 
                            theme={'bubble'} 
                            modules={{
                              clipboard: {
                                matchVisual: false
                              }
                            }}
                            />
                          </div>                               
                          <div className="content_margin_top_14"></div>
                        </div>                              
                      )}
                      {item.type_content == 'img' && (
                        <div>
                          <div className="div_box_type_content_img">
                            <div>
                                <div className="div_box_type_content_img_head_content">
                                    {item.head_content}
                                </div>
                                {item.FileInfoList?.length > 0 && ( 
                                    <div className="div_box_type_content_img_url">
                                        <img src={item.FileInfoList[0].url } />
                                    </div>
                                )}
                                <div className="div_box_type_content_img_sub_content">
                                    {item.sub_content}
                                </div>
                            </div>
                          </div>
                          <div className="content_margin_top_14"></div>
                        </div>
                      )}                            
                      {item.type_content == 'vdo' && (
                        <div>
                          <div className="div_box_type_content_vdo">
                            {item.content && (
                              <div>
                                  <div>
                                    <ReactPlayer
                                        url={item.content}
                                        className='react-player'
                                        playing={false}
                                        width='720px'
                                        height='440px'
                                        onProgress={(e) => handleProgressVDO(e.playedSeconds)}
                                        controls={true}
                                    />
                                  </div>
                                  <div className="div_box_type_content_vdo_sub_content">
                                    {item.sub_content}
                                  </div>

                              </div>
                            )}
                          </div>
                          <div className="content_margin_top_14"></div>
                        </div>
                      )}
                      {item.type_content == 'pdf' && (
                        <div>
                          {item.FileInfoList?.length > 0 && ( 
                            <div className="div_box_type_content_pdf">
                              <div className="icon-wrapper">
                                <img src={iconFile} />
                              </div>
                              <div className="detail-wrapper">
                                  <div className="file-name">
                                    <a href={item.FileInfoList[0].url} target="_blank" rel="noreferrer">
                                      {item.FileInfoList[0].name}
                                    </a>
                                  </div>
                                  <div className="file-size">{item.FileInfoList[0].size} KB</div>
                              </div>
                            </div>
                          )}

                          <div className="content_margin_top_14"></div>
                        </div>
                      )}
                      {item.type_content == 'url' && (
                        <div>
                          <div className="div_box_type_content_url">
                            <div className="head_content">
                              {item.head_content}
                            </div>
                            <div className="content_margin_top_14"></div>
                            {item?.url_list?.map((item_sub, index_sub) => (
                              <div className="url_list">
                                    <a href={item_sub.link_url} target="_blank" rel="noreferrer">
                                      {item_sub.link_name}
                                    </a>
                              </div>                  
                            ))}
                          </div>
                          <div className="content_margin_top_14"></div>
                        </div>
                      )}
                    </div>
                  ))}
                  {NumVDO > 0  && (
                    <div>
                      <DivProgress>
                        <div className="progress_text">
                          <div className="headline">ความคืบหน้าในการเรียนรู้</div>
                          <div>เมื่อดูครบถ้วน 100% จะสามารถจบการเรียนรู้ได้</div>
                          <div>กรุณาอยู่บทหน้าจอ และเล่น VDO ให้ครบตามเกณฑ์</div>
                        </div>
                        { DataInfo?.elearning_user_status != 'เรียนรู้แล้ว' && (ActiveButton == false) ? (
                          <div className="progress_display">
                            <div className="linear_progress">
                              <BorderLinearProgress variant="determinate" value={Math.floor( (TotleTimeVDO / DataInfo.elearning_duration_second) * 100 )} />
                            </div>
                            <div>
                              { Number(Math.floor( (TotleTimeVDO / DataInfo.elearning_duration_second) * 100 )).toFixed(0) }%
                            </div>
                          </div>
                        ) : (
                            <div className="progress_display">
                              <div className="linear_progress">
                                <BorderLinearProgress variant="determinate" value={100} />
                              </div>
                              <div>
                                100%
                              </div>
                            </div>
                          )
                        }

                      </DivProgress>
                    </div>
                  )}
                  <div className="button_end_content">
                    {DataInfo?.status_exam == 2  && (
                      <div>
                        {
                          /*
                          { DataInfo?.elearning_user_status != 'เรียนรู้แล้ว' && (ActiveButton == false && TotleTimeVDO >= DataInfo.elearning_duration_second) ? (
                              <Button append={<FaArrowRight size={20} />} onClick={() => ProcessAPI()} >{'จบการเรียนรู้'}</Button>
                            ) : (
                              <Button append={<FaArrowRight size={20} />} className="button_disable" >{'จบการเรียนรู้'}</Button> 
                            )
                          }
                          */
                        }
                        

                        { DataInfo?.elearning_user_status != 'เรียนรู้แล้ว' && (ActiveButton == true) && (
                            <Button append={<FaArrowRight size={20} />} onClick={() => ProcessAPI()} >{'จบการเรียนรู้'}</Button>
                        )}
                        { DataInfo?.elearning_user_status != 'เรียนรู้แล้ว' && (ActiveButton == false) && (
                            <Button append={<FaArrowRight size={20} />} className="button_disable" >{'จบการเรียนรู้'}</Button>
                        )}
                        
                        { DataInfo?.elearning_user_status == 'เรียนรู้แล้ว'  && (
                          <Button append={<FaArrowRight size={20} />} className="button_disable" >{'จบการเรียนรู้'}</Button>
                        )}

                        

                      </div>
                    )}
                    {DataInfo?.status_exam == 1 && (
                      <div>

                        { DataInfo?.exam_status != 3 && (ActiveButton == true) && (
                            <Button append={<FaArrowRight size={20} />} onClick={() => ProcessAPI()} >{'จบการเรียนรู้ และเริ่มทำแบบทดสอบ'}</Button>
                        )}
                        { DataInfo?.exam_status != 3 && (ActiveButton == false) && (
                            <Button append={<FaArrowRight size={20} />} className="button_disable" >{'จบการเรียนรู้ และเริ่มทำแบบทดสอบ'}</Button>
                        )}
                        
                        { DataInfo?.exam_status == 3  && (
                          <Button append={<FaArrowRight size={20} />} className="button_disable" >{'จบการเรียนรู้ และเริ่มทำแบบทดสอบ'}</Button>
                        )}
                        
                      </div>
                      
                    )}
                  </div>
                  {
                    /*
                                      {window.location.hostname != 'cpdmember.coe.or.th'  && (
                    <div>
                      <div>
                        เวลาในการเช็คจบการเรียนรู้ : {DataInfo.elearning_duration_second}
                      </div>
                      <div>
                        จำนวนเวลาอยู่ในหน้าจอ (วินาที) : {timeSpentOnPage}
                      </div>
                      <div>
                        จำนวนเวลาที่ดู VDO (วินาที) : {TotleTimeVDO}
                      </div>
                    </div>
                    )}
                    */
                  }

                </div>
              </DivDataContent>
            </div>
          </DivFullContent>
        </div>
      )}

      {/* ModalPoint */}
      <Modal open={ModalConfirmSuccess} disableBackdropClick>
              <DivModalConfirm>
                <div className="header_confirm">
                  <div className="justify-content-end">
                    <IoMdClose size={20} className="icon" onClick={() => setModalConfirmSuccess(false)} />
                  </div>
                  <div className="justify-content-center">
                    <img src={ HappyIcon } alt="otp icon" />
                  </div>
                  <div className="justify-content-center modal_text_bold">
                    {'ยินดีด้วย! คุณจบการเรียนรู้แล้ว'}
                  </div>
                </div>
                <div className="css_magin_top_22"></div>
                <div className="justify-content-center text_point_cpd">
                  ได้รับ {DataInfoReturn.point} คะแนน CPD
                </div>
                <div className="css_magin_top_22"></div>
                <div>
                  {'สามารถตรวจสอบประวัติคะแนนได้ที่เมนู'} <span className="link_goto" onClick={gotoMyCPD}>ตรวจสอบคะแนน My CPD</span>
                </div>    
                <div className="css_magin_top_23"></div>
                <div className="justify-content-center">
                  <Button onClick={gotoPageBack}>กลับสู่หน้าหลัก</Button>
                </div>
              </DivModalConfirm>
      </Modal>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={gotoPageBack}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />

      <Modal open={ModalWarning} disableBackdropClick>
          <DivModalWarningElearning>
              <div className="header_confirm">
                <div div className="justify-content-end">
                    <IoMdClose size={20} className="icon" onClick={() => setModalWarning(false)} />
                </div>
                <div className="justify-content-center">
                  <img src={HappyIcon} alt="otp icon" />
                </div>
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center text_point_cpd">
                  ขณะนี้ท่านกำลังเรียนรู้
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.topic_name}
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.sub_detail}
              </div>
              <div className="justify-content-center">
                กรุณาจบการเรียนรู้ก่อนเริ่มบทเรียนอื่นๆ
              </div>
              <div className="css_magin_top_22"></div>
              <div className="dialog-button">
                <ButtonSecondary onClick={IsModalConfirmCancle} >ต้องการยกเลิกบทเรียนนี้</ButtonSecondary>
                <Button onClick={GotoElearningInfo}>ดำเนินการต่อ</Button>           
              </div>
          </DivModalWarningElearning>
      </Modal>

      <Modal open={ModalConfirmCancle} disableBackdropClick>
          <DivModalWarningElearning>
              <div className="header_confirm">
                <div className="justify-content-center">
                  <img src={warningIcon} alt="otp icon" />
                </div>
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center text_point_cpd">
                  ยืนยันการยกเลิกการเรียนรู้
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.topic_name}
              </div>
              <div className="justify-content-center">
                หากยกเลิกการเรียนรู้ก่อนจบการเรียนรู้ คุณจะไม่ได้รับคะแนน
              </div>
              <div className="css_magin_top_22"></div>
              <div className="dialog-button">
                <ButtonSecondary onClick={() => setModalConfirmCancle(false)} >กลับ</ButtonSecondary>
                <Button onClick={ProcessMemberCancleElearning}>ยืนยันและยกเลิก</Button>           
              </div>
          </DivModalWarningElearning>
      </Modal>
    </Div>
  )
}

export default ElearningInfo

