import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser,DateTimeHHmmDisplay } from '../../utils/common'
import { ApiGetVWMemberEventPoint,ApiMemberAppeal } from '../../redux/actions/appeal'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Date from '../../components/form/Date'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import UploadFile from '../../components/upload/UploadFile'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaPlus } from "react-icons/fa6";
import { RiDeleteBinLine } from "react-icons/ri";
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import Fileattachment from '../../assets/images/file-attachment-02.png'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'

const Div = styled.div`
margin-bottom: 50px;
padding: 10px;
.box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
}

.border_header {
 border-bottom: 1px solid rgb(234, 236, 240);
}
.dialog-footer {
    text-align: end;
    right: 0px;
    bottom: 70px;
    background-color : #FFFFFF;
    position: absolute;
    width: 100%;
    padding: 0px 45px;
    .button_margin {
        margin: 10px;
    }
}
.css_magin_top_16 {
    margin-top: 16px;
}
.navigate_click{
    color: #0BA5EC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-decoration-line: underline;
    display: inline-block;
    align-self: flex-end;
    cursor: pointer;
}
`


const DivInfo = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .group_content{
    display: grid;
    grid-template-columns: repeat(2, 38% [ col-start ] 60% [ col-end ]);
    gap: 2%;
  }
`

const DivWarning = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    /*border: 1px solid #475467;*/
    background: #FFFFFF;
    /*padding: 1rem;*/
    .d-flex {
        display: flex;
        align-items: flex-start;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color : #344054;
      }
      .detail-warning{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #667085;
        margin: 5px 0px;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`

const DivContentLeft = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;  
    .main_box_fill_data {
        margin: 16px 24px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 20px;
        align-items: end;
        .topic_fill {
          font-size: 14px;
          color: #212121;
          margin-bottom: 5px;
        }
        .topic_detail {
          font-size: 14px;
          color: #757575;
        }
    } 
    .TextField_event_code{
        min-width : 400px;
    }
    .group_text_search{
        display: flex;
        gap: 10px;
    }
    .group_event_display{
        margin: 5px 24px;
        display: grid;
        grid-template-columns: repeat(2, 25% [ col-start ] 70% [ col-end ]);
        gap: 1%;
        .topic_fill {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            color : #182230;
        }
        .topic_detail {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color : #475467;
        }
    }
    .message_event_error{
        margin: 5px 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #A91F23;
    }
`

const DivContentRight = styled.div`
    background: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;
    .main_box_fill_data {
        margin: 16px 24px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 20px;
        align-items: end;
        .topic_fill {
          font-size: 14px;
          color: #212121;
          margin-bottom: 5px;
        }
        .topic_detail {
          font-size: 14px;
          color: #757575;
        }
    }

    .InputTextArea_css {
        width: 100%;
        font-size: 14px;
    }
`



const TabAppealForm = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const initMessageModal = {
        headline: '',
        subtitle: '',
        description: '',
    }
    const [ModalConfirm, setModalConfirm] = useState(false)
    const [ConfirmMessageModal, setConfirmMessageModal] = useState(initMessageModal)
    const [successModal, setSuccessModal] = useState(false)
    const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
    const [failModal, setFailModal] = useState(false)
    const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

    const initDataInfo = {

        event_code : null,
        petition_type : null,
        description : null,
        list_file : [],
        
        ref_activity_id : null,
        ref_activity : null,
        user_activity_point_id : null,

        activity_name : null,
        engineering_field_name : null,
        organizations_name : null,
        events_start_at : null,
    };
    const [DataInfo, setDataInfo] = useState(initDataInfo)
    const [MessageEventCode, setMessageEventCode] = useState(null)
    const [petition_type_option, setpetition_type_option] = useState([
        {
            value : 1,
            text : 'คะแนนขึ้นผิดพลาด'
        },
        {
            value : 2,
            text : 'ยังไม่ได้รับคะแนน'
        },
    ])


    const onConfirm = () => {
        setConfirmMessageModal({
            headline: 'ยืนยันการยื่นคำร้อง',
            subtitle: 'รหัสกิจกรรม ' + DataInfo?.event_code,
            description: '',
        })
        setModalConfirm(true);
    }

    const onProcessAPI = () => {
        const data_to_api = DataInfo;
        dispatch(ApiMemberAppeal(data_to_api)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
                setSuccessMessageModal({
                    headline: 'ทำรายการสำเร็จ',
                    subtitle: 'กรุณารอเจ้าหน้าที่ตรวจสอบรายการของท่าน 3-5 วันทำการ',
                })
                setSuccessModal(true);
            }else{
              setFailMessageModal({
                headline: 'internal server error',
                description: '',
              })
              setFailModal(true)
            }
        })
    }

    const onFilterEvent = () => {
        const filter = {
            skip: 0,
            limit: 10,
            event_code: DataInfo?.event_code,
            ref_activity : 'กิจกรรมแม่ข่าย'
        }
        dispatch(ApiGetVWMemberEventPoint(filter)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if (data_info.total > 0) {
                //alert(JSON.stringify(data_info.data))
                const this_data_info = data_info.data[0];
                const ref_activity_id = this_data_info.ref_activity_id;
                const ref_activity = this_data_info.ref_activity;
                const user_activity_point_id = this_data_info.id;
                const activity_name = this_data_info.activity_name;
                const engineering_field_name = this_data_info.engineering_field_name;
                const organizations_name = this_data_info.organizations_name;
                const events_start_at = this_data_info.events_start_at;

                setDataInfo({ 
                    ...DataInfo, 
                    ref_activity_id: ref_activity_id,
                    ref_activity: ref_activity,
                    user_activity_point_id: user_activity_point_id,
                    activity_name: activity_name,
                    engineering_field_name: engineering_field_name,
                    organizations_name: organizations_name,
                    events_start_at: events_start_at,
                });
                setMessageEventCode('event_ok')
              }else{
                setDataInfo({ 
                    ...DataInfo, 
                    ref_activity_id: null,
                    ref_activity: null,
                    user_activity_point_id: null,
                    activity_name: null,
                    engineering_field_name: null,
                    organizations_name: null,
                    events_start_at: null,
                });
                setMessageEventCode('event_error')
              }             
            }
        })
    }

    const onSuccess = () => {
        window.location.href = '/Appeal/AppealInfo'
    }

    const onGotoSelfDeclaration = () => {
        navigate('/Activity/SelfDeclaration')
    }

    const onGotoMyCpd = () => {
        navigate('/Member/CpdPoint')
    }

    //--- ST UploadFile
    const [FileInfoList, setFileInfoList] = useState([])
    const limitFileSize = 100; //--- 100MB
    const multiple = true;
    const error = false;
    const disabled = false;
    const allowedTypes = [];
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    const handleAddFile = async (ObjData) => {
        console.log(ObjData);
        let newDataElement = {
          key_file_id : ObjData.key_file_id,
          file_name : ObjData.original_filename,
          file_url : ObjData.url_file,
          file_size : ObjData.file_size,
        }
        const newStateData = [...DataInfo.list_file, newDataElement]
        setDataInfo({ ...DataInfo, list_file : newStateData })
    }
    const handleDeleteFile = (key_file_id) => {
        setFileInfoList(FileInfoList.filter((v) => v.key_file_id !== key_file_id));
        const newDataElement = DataInfo.list_file.filter((v) => v.key_file_id !== key_file_id)
        setDataInfo({ ...DataInfo, list_file : newDataElement })
    }
    //--- ED UploadFile
  return (
    <Div>
        <DivInfo>
            <div className="css_magin_top_16"></div>
            <DivWarning>
                <div className="d-flex">
                    <div className="icon-warning-container">
                        <div className="box_icon" >
                            <PiWarningCircleBold size={25} />
                        </div>                    
                    </div>
                    <div className="warning-message-container">
                        <div className="headline-warning">
                            คำอธิบายการส่งคำร้องอุทธรณ์คะแนน CPD
                        </div>
                        <div className="detail-warning">
                            1. กรณีที่ท่านเข้าร่วมกิจกรรมองค์กรแม่ข่าย และได้ทำการ Scan QR Code ลงทะเบียนแล้ว แต่ท่านยังไม่ได้รับคะแนน
                        </div>
                        <div className="detail-warning">
                            2. ระบบขึ้นคะแนนกิจกรรมของท่านผิดพลาด
                        </div>
                        <div className="detail-warning">
                            หมายเหตุ
                        </div>
                        <div className="detail-warning">
                            - กรณีท่านเข้าร่วมกิจกรรมองค์กรแม่ข่ายอื่นๆ และยังไม่ได้ Scan QR Code ท่านสามารถแจ้งคะแนนด้วยตนเองได้ที่เมนู <span className="navigate_click" onClick={onGotoSelfDeclaration}>‘บันทึกคะแนนกิจกรรม’</span>
                        </div>
                        <div className="detail-warning">
                            - กรณีที่ท่านต้องการยกเลิกกิจกรรม หรือยกเลิกคะแนน ท่านสามารถทำด้วยตนเองได้ที่ <span className="navigate_click" onClick={onGotoMyCpd}>‘ประวัติกิจกรรม’</span>
                        </div>
                    </div>
                </div>                                    
            </DivWarning> 
            <div className="css_magin_top_16"></div>
            <div className="group_content">
                <DivContentLeft>
                    <div>
                        <div className="main_box_fill_data">
                            <div>
                                <div className="topic_fill">รหัสกิจกรรม</div>
                                <div className="group_text_search">
                                    <div>
                                        <TextField
                                            name="name"
                                            className="TextField_event_code"
                                            value={DataInfo.event_code}
                                            onChange={(e) => setDataInfo({ ...DataInfo, event_code: e.target.value })}
                                            placeholder={'ค้นหารหัสกิจกรรม'}
                                        />
                                    </div>
                                    <div>
                                        <Button onClick={onFilterEvent}>
                                            {'ค้นหา'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {MessageEventCode == 'event_ok' && (
                            <div>
                                <div className="group_event_display">
                                    <div className="topic_fill">ชื่อกิจกรรม</div>
                                    <div className="topic_detail">{DataInfo.activity_name ? DataInfo.activity_name : '-'}</div>
                                </div>
                                <div className="group_event_display">
                                    <div className="topic_fill">สาขาทางวิศวกรรม</div>
                                    <div className="topic_detail">{DataInfo.engineering_field_name ? DataInfo.engineering_field_name : '-'}</div>
                                </div>
                                <div className="group_event_display">
                                    <div className="topic_fill">จัดโดย</div>
                                    <div className="topic_detail">{DataInfo.organizations_name ? DataInfo.organizations_name : '-'}</div>
                                </div>
                                <div className="group_event_display">
                                    <div className="topic_fill">วันที่เริ่มกิจกรรม</div>
                                    <div className="topic_detail">{DataInfo.events_start_at ? DateTimeHHmmDisplay(DataInfo.events_start_at) : '-'}</div>
                                </div>                               
                            </div>
                        )}
                        {MessageEventCode == 'event_error' && (
                            <div className="message_event_error">
                                ท่านไม่มีรายชื่ออยู่ในกิจกรรมนี้ ท่านสามารถบันทึกคะแนนด้วยตนเอง
                            </div>
                        )}
                    </div>
                </DivContentLeft>
                <DivContentRight>
                    <div>
                        <div className="main_box_fill_data">
                            <div>                            
                                <div className="topic_fill">ประเภทคำร้อง</div>
                                <div>
                                    <Dropdown
                                        id={`dd_org_type`}
                                        value={DataInfo.petition_type}
                                        optionList={petition_type_option}
                                        onChange={(v) => setDataInfo({ ...DataInfo, petition_type: v })}
                                        placeHolder={'กรุณาเลือก'}
                                        ValueplaceHolderIsNull={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="main_box_fill_data">
                            <div>
                                <div className="topic_fill">กรอกรายละเอียด</div>
                                <div>
                                    <InputTextArea
                                        className="InputTextArea_css"
                                        initValue={DataInfo.description}
                                        onChange={(e) => setDataInfo({ ...DataInfo, description: e.target.value  })}
                                        placeHolder="กรุณากรอกรายละเอียดเกี่ยวกับเนื้อหาการอุทธรณ์คะแนน"
                                        borderColor={'var(--Gray-300)'}
                                        borderWidth="1px"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="main_box_fill_data">
                            <div>
                                <div className="topic_fill">แนบเอกสารประกอบ</div>
                                <div>
                                    <UploadFile
                                        multiple={multiple}
                                        accept={acceptType}
                                        file={FileInfoList.map((v) => ({ ...v, url: `${v.url}` }))}
                                        onAddFile={handleAddFile}
                                        onDeleteFile={handleDeleteFile}
                                        error={error}
                                        disabled={disabled}
                                        FileListData={FileInfoList}
                                        setFileListData={setFileInfoList}
                                        isOneFile={false}
                                        limitFileSize={limitFileSize}
                                        subtext={'PDF, JPG, JPEG, PNG (max. 10MB)'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DivContentRight>
            </div>
        </DivInfo>
        {/* Button */}
        <div className="dialog-footer">
            <div className="border_header"></div>
            <div className="button-footer">
                <div>
                    <Button className="button_margin" onClick={onConfirm} disabled={!DataInfo.user_activity_point_id}>
                        {'ส่งคำร้อง'}
                    </Button>
                </div>
            </div>
        </div>

        <DialogConfirmV2
            open={Boolean(ModalConfirm)}
            onClose={() => setModalConfirm(false)}
            onNo={() => setModalConfirm(false)}
            onSubmit={() => {
                setModalConfirm(false)
                onProcessAPI()
            }}
            icon={successIcon}
            title={ConfirmMessageModal.headline}
            nameItem={ConfirmMessageModal.subtitle}
            textYes='ยืนยัน'
            textNo='ยกเลิก'
        />

        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => {
              setSuccessModal(false)
              onSuccess()
            }}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />        
    </Div>
  )
}

export default TabAppealForm
