import { forwardRef } from 'react'
import styled from 'styled-components'
import ModalUnstyled from '@mui/base/Modal'
import Fade from '@mui/material/Fade'

import PropTypes from 'prop-types'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 2000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Div = styled.div`
  background: var(--Base-White);
  border-radius: 8px;
  margin: 12px;
  min-width: ${(props) => props.minWidth || 'auto'};
  max-width: ${(props) => props.maxWidth || 'auto'};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
`

let Backdrop = (props, ref) => {
  const { open, /*omit*/ ownerState, ...other } = props
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  )
}
Backdrop = forwardRef(Backdrop)

Backdrop.propTypes = {
  open: PropTypes.func,
  ownerState: PropTypes.any,
}

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(52, 64, 84, 0.6);
  -webkit-tap-highlight-color: transparent;
`

const Modal = ({ open, onClose, disableBackdropClick, children, width, minWidth, maxWidth , height, ...props }) => {
  return (
    <StyledModal
      open={open}
      onClose={disableBackdropClick ? null : onClose}
      slots={{ backdrop: StyledBackdrop }}
      closeAfterTransition
      {...props}
    >
      <Fade in={open}>
        <Div className="modal-content" width={width} height={height} minWidth={minWidth} maxWidth={maxWidth}>
          {children}
        </Div>
      </Fade>
    </StyledModal>
  )
}

Modal.propTypes = {
  open: PropTypes.func,
  onClose: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  children: PropTypes.node,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
}

export default Modal
