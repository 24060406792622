import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {  getAdminUser } from '../utils/common'

import Button from '../components/form/button/Button'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'

const Div = styled.div`

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 18px;
  }
  .content-detail{
    margin-top: 15px;
    display: flex;
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-detail-card{
    border-radius: 12px;
    border: 1px solid var(--Gray-200);
    background: var(--Base-White);
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px;
    display: inline-block;
    padding: 24px;
    max-width: none;
  }
  .box-width-70{
    width: 70%;
  }
  .box-width-30{
    width: 30%;
  }
  .title_box{
    font-weight: bold;
  }
  .header_box{
    margin-top: 15px;
    
  }
  .data_info_box{
    color: var(--Base-Font-Focus-Color);
    font-weight: 600;
  }
  .box_flex{
    display: grid;
    grid-template-columns: repeat(2, 45% [col-start] 45% [col-end]);
    gap: 20px;
  }
  .button_register{
    margin-top: 15px;
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color:#067647;
    background: #ECFDF3;
    border: 1px #ABEFC6 solid;

    &.expire {
      color:#B42318;
      background: #FEF3F2;
      border: 1px #FECDCA solid;
    }

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color:#067647;
      

      &.expire {
        color:#B42318;
      }

    }
  }
`



const Home = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()

  const [DataInfo, setDataInfo] = useState(null)


  return (
    <Div>
      <header>
        <div className="content-title">
          ยินดีต้อนรับเข้าสู่ CPD
        </div>
        <div className="content-title-text">
        {user?.full_name ? user?.full_name : '-'}
        </div>
        <div className="border_header"></div>
      </header>

    </Div>
  )
}

export default Home
