import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import InputMask from "react-input-mask";

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,TimeDisplayHHmm,DateDisplayFullNameMonth  } from '../../utils/common'
import { ApiExternalGetEventInfo,ApiExternalCheckInGeneral } from '../../redux/actions/events'
import { getTitle } from '../../redux/actions/master'
import { validateEmail } from '../../utils/validation'

import Button from '../../components/form/button/Button'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import iconPlus from '../../assets/images/icon-plus.svg'
import logoIcon from '../../assets/images/logo-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
    .css_magin_top_12 {
        margin-top: 12px;
    }
    .css_magin_top_32 {
        margin-top: 32px;
    }
    .img-container {
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        
        img {
          width: 150px;
            /*height: 48px;*/
        }
    
    }
    .title{
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      color : #101828;
      text-align: center;
    }
    .desc{
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #475467;
      text-align: center;
    }
    .text_general{
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        color : #6941C6;
    }
    .event_name{
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color : #182230;
    }
    .event_date{
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color : #182230;
    }
    .button-login {
        display: block;
        width: 100%;
    }

    .box_input_1{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 20px;
        padding-top: 10px;
    }
    @media screen and (max-width: 440px) {
      max-width: 300px;
      .box_input_1{
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
        padding-top: 10px;
      }
    }
`

const DivStyleInputMask = styled.div`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;
  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const EventCheckInGeneral = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { id } = useParams()
  const navigate = useNavigate()

  const initMessageModal = {
    headline: '',
    subtitle: '',
    description: '',
}
const [successModal, setSuccessModal] = useState(false)
const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
const [failModal, setFailModal] = useState(false)
const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [DataInfo, setDataInfo] = useState({
    event_name : '',
    start_at : ''
  })
  const [UserName, setUserName] = useState('')
  const [title_option, settitle_option] = useState([])

  useEffect(() => {
    dispatch(getTitle()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newtitle_option = data_info.data.map((v) => ({
                text: v?.name_th_th,
                value: v?.id,
            }))
            settitle_option([...newtitle_option])
        }
    })
  }, [dispatch])

  const [RegisterData, setRegisterData] = useState({
    title_id: '',
    first_name: null,
    last_name: null
  })

  const [RegisterDataError, setRegisterDataError] = useState({
    title_id: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุคำนำหน้า',
    },
    first_name: {
      show_errorMessage: false,
      errorMessage: 'กรุณาระบุชื่อ',
    },
    last_name: {
        show_errorMessage: false,
        errorMessage: 'กรุณาระบุนามสกุล',
    },
  })
  
  useEffect(() => {
    const sent_data_to_api = {
        event_id : id,
    };
    dispatch(ApiExternalGetEventInfo(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.data_info){
                setDataInfo({
                    event_name : data_info.data_info.name,
                    start_at : data_info.data_info.start_at
                })
            }
        }
    })
  }, [dispatch])
  
  const isValid = () => {
    let data_valid = RegisterData
    let data_check_valid = RegisterDataError
    let errors = []
    Object.keys(RegisterDataError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'email') {
            data_check_valid[k].errorMessage = 'กรุณาระบุEmail'
          }
        } else {
          if (k == 'email') {
            //--- valid email
            if (!validateEmail(data)) {
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'Email ไม่ถูกต้อง'
              data_check_valid[k].show_errorMessage = true
            } else {
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setRegisterDataError({ ...RegisterDataError })
    return errors
  }

  const ApiProcess = () => {
    if (isValid().length == 0) {
        const data_to_api = {
            event_id : id,
            ...RegisterData
        };
        dispatch(ApiExternalCheckInGeneral(data_to_api)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if(data_info.code == 200){
                setSuccessMessageModal({
                    headline: 'Check-in สำเร็จ',
                    subtitle: data_info.full_name,
                    description: 'ขอบคุณสำหรับการเข้าร่วมกิจกรรม',
                })
                setSuccessModal(true);
              }else{
                setFailMessageModal({
                  headline: 'ขออภัยไม่สามารถ Check-in ได้',
                  description: data_info.message,
                })
                setFailModal(true)
              }
            }else{
              setFailMessageModal({
                headline: 'internal server error',
                description: '',
              })
              setFailModal(true)
            }
        })
    }
  }

  const gotoPreregisterGeneral = () => {
    navigate('/EventCheckInGeneral/' + id)
  }
  
  return (
    <Div>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">Check-in<br/>เข้าร่วมกิจกรรม</div>
        <div className="css_magin_top_32"></div>
        <div className="text_general">สำหรับบุคคลทั่วไป</div>
        <div className="css_magin_top_12"></div>
        <div className="event_name">{DataInfo.event_name}</div>
        <div className="css_magin_top_12"></div>
        <div className="event_date">วันที่ {DateDisplayFullNameMonth(DataInfo.start_at)} เวลา {TimeDisplayHHmm(DataInfo.start_at)} น.</div>
        <div className="css_magin_top_32"></div>
        <div className="box_input_1">
            <div>
                <Field className="field" label="คำนำหน้า*">
                    <Dropdown
                        id={`dd_title_option`}
                        value={RegisterData.title_id}
                        optionList={title_option}
                        onChange={(v) => setRegisterData({ ...RegisterData, title_id: v })}
                        placeHolder={'เลือกคำนำหน้า'}
                        error={Boolean(RegisterDataError.title_id.show_errorMessage)}
                    />
                    {RegisterDataError.title_id.show_errorMessage == true && (
                        <DivErrorMessage>{RegisterDataError.title_id.errorMessage}</DivErrorMessage>
                    )}
                </Field>
            </div>
            <div>
                <Field className="field" label="ชื่อ*">
                    <TextField
                    placeholder="กรุณาระบุ"
                    value={RegisterData.first_name}
                    onChange={(e) => setRegisterData({ ...RegisterData, first_name: e.target.value })}
                    error={Boolean(RegisterDataError.first_name.show_errorMessage)}
                    />
                    {RegisterDataError.first_name.show_errorMessage == true && (
                        <DivErrorMessage>{RegisterDataError.first_name.errorMessage}</DivErrorMessage>
                    )}
                </Field>
            </div>
            <div>
                <Field className="field" label="นามสกุล*">
                    <TextField
                    placeholder="กรุณาระบุ"
                    value={RegisterData.last_name}
                    onChange={(e) => setRegisterData({ ...RegisterData, last_name: e.target.value })}
                    error={Boolean(RegisterDataError.last_name.show_errorMessage)}
                    />
                    {RegisterDataError.last_name.show_errorMessage == true && (
                        <DivErrorMessage>{RegisterDataError.last_name.errorMessage}</DivErrorMessage>
                    )}
                </Field>
            </div>
        </div>
        <div className="css_magin_top_32"></div>
        <Button
            className="button-login"
            type="submit"
            onClick={ApiProcess}
          >
            ลงทะเบียนเข้าร่วมกิจกรรม
          </Button>

          <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventCheckInGeneral
