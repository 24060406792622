import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {  getAdminUser } from '../../utils/common'
import { ApiGetEngineeringCourse,ApiGetMemberORG } from '../../redux/actions/master'
import { ApiGetElearningData,ApiMemberCheckElearning,ApiMemberCancleElearning } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import SidePanel from '../../components/common/SidePanel'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import Modal from '../../components/common/Modal'
import CpdConsent from '../../pages/CpdConsent'
import Checkbox from '../../components/form/Checkbox'
import AutoComplete from '../../components/form/AutoComplete'
import ModalKnowledge from '../../components/feature/knowledge/ModalKnowledge'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconFileDowload from '../../assets/images/icon-file-download.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import iconDuplicate from '../../assets/images/Duplicate.png'
import warningIcon from '../../assets/images/warning-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconSearch from '../../assets/images/iconSearch.png'

import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { TiStarOutline } from "react-icons/ti";
import { AiOutlinePushpin } from "react-icons/ai";
import { LiaAwardSolid } from "react-icons/lia";
import { IoTimeOutline } from "react-icons/io5";
import { LuFileCheck } from "react-icons/lu";
import { FaListUl } from "react-icons/fa";
import { IoMdClose } from 'react-icons/io'
import { RiDeleteBinLine } from "react-icons/ri";
import { PiWarningCircleBold } from 'react-icons/pi'
import HappyIcon from '../../assets/images/Haapy-icon.png'

const Div = styled.div`
   margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }

  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .css_magin_top_32 {
    margin-top: 32px;
  }
  .css_magin_top_20 {
    margin-top: 20px;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .topic_engineering{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .header_filter_text{
    color: var(--Base-Font-Focus-Color);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
  }
  .box_tab{
    display: flex;
    gap: 48px;
  }
  .box_filter{
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .box_num_data{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dd_sort{
    min-width: 110px;
  }
  .css_no_data{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color : #101828;
    margin-left: 5px;
  }
`
const DivTab = styled.div`
/*
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
*/
display: flex;
flex-wrap: wrap;
min-width: 100%;
.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    border: 1px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    font-family: inherit;
    margin: 12px 0px;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    /*border-bottom: 2px solid var(--Tab-Border-Current) !important;*/
    border: 1px solid var(--Tab-Border-Default);
    color: #FFFFFF; 
    background-color: var(--Tab-Border-Current);
    font-family: inherit;
    margin: 12px 0px;
}
`
const DivContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  gap : 30px;
  .box_data{
    width : 410px;
    cursor: pointer;
    padding : 10px;
    border-radius: 16px;
    &:hover {
      /*box-shadow: 10px -10px 10px 0px #A91F23;*/
      /*box-shadow: 0px 0px 10px 0px #A91F23;*/
      border: 1px solid #F2F4F7;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    }
    
    .cover_img{
      text-align: center;
      margin-bottom: 24px;
      img {
        border-radius: 16px;
          width: 100%;
          height: 240px;
      }
    }
    .box_engineer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      .box_text_engineer{
        color: #A91F23;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
      .box_text_new{
        color: #EAAA08;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 5px;
        .css_icon{
          display: flex;
          align-items: center;
        }
      }
    }
    .box_text_topic{
      color: #101828;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      height: 60px;
      margin-bottom: 24px;
    }
    .box_organizations_name{
      color: #667085;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 24px;
    }
    .box_other{

      margin-bottom: 24px;
      .box_other_sub{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .content-topic-icon{
        display: flex;
        align-items: center;
        gap: 10px;
        color : #667085;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
      }
      .css_icon{
        display: flex;
        align-items: center;
      }
      .text_input{
        color : #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .box_user_status{
      .status-badge {
        width: fit-content;
        padding: 2px 6px;
        text-align: center;
        font-weight: 500;
        border-radius: 16px;
        color: #475467;
        background: #f5f5f4;
        border: 1px #e7e5e4 solid;
        .status {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #475467;    
        }
      }
    }
  }
`

const DivModalConsent = styled.div`
  padding: 32px 32px;
  /*min-width: 500px;*/
  width: 1073px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color : #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .d-flex {
    display: flex;
    gap: 10px;
  }
`
const DivFilter = styled.div`
min-height: 68px;
border: 1px solid #EAECF0;
background-color: #F9FAFB;
border-radius: 12px;
/*margin-bottom: 21px;*/
padding: 16px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
        gap:10px;
    }
}
.css_filter_name {
    min-width: 380px;
    /*margin-right: 10px;*/
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}
.field_EngineeringCourse{
  min-width: 320px;
}
.field_read{
  min-width: 190px;
}
.field_org{
  min-width: 250px;
}
.dd_organizations{
  min-width: 300px;
}
.field_keyword{
  min-width: 520px;
}
/*--- ST หมวดหมู่ --- */
.button_EngineeringCourse{
  text-align: left;
}
.box_dropdown_EngineeringCourse{
  position: relative;
  width: 300px;
}
.dropdown_EngineeringCourse_none{
  display : none;
}
.dropdown_EngineeringCourse {
  position: absolute;
  z-index: 1000;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  
  height: 400px;
  overflow-y: auto;
  min-width: 320px;

  .check_box_css{
    display : flex;
  }
  .text_category{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

/*--- ED หมวดหมู่ --- */

/*--- ST การอ่าน --- */
.button_read{
  text-align: left;
}
.box_dropdown_read{
  position: relative;
  width: 190px;
}
.dropdown_read_none{
  display : none;
}
.dropdown_read {
  position: absolute;
  z-index: 1000;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  min-width: 190px;
  
  /*height: 400px;*/
  overflow-y: auto;

  .check_box_css{
    display : flex;
  }
  .text_category{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

/*--- ED การอ่าน --- */

.box_button_badge{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}
.box_badge{
  width: 80%;
}
.box_button{
  display: flex;
  gap: 10px;
}
`

const DivBadgeEngineer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap : 10px;
  margin-top : 0px;
  .status-badge {
    width: fit-content;
    padding: 2px 6px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 8px;
    color: #B42318;
    background: #FEF3F2;
    border: 1px #FECDCA solid;

    display: flex;
    align-items: center;

    .status {
      font-size: 18px;
      margin-left: 2px;
      color: #B42318;
      cursor: pointer;
    }
  }

  .clearAll {
    width: fit-content;
    padding: 2px 6px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    border-radius: 8px;
    color: #475467;
    background: #FFFFFF;
    border: 1px #FFFFFF solid;

    display: flex;
    align-items: center;

    cursor: pointer;

    .status {
      font-size: 18px;
      margin-right: 2px;
      color: #475467;
      
    }
  }
`

const DivNoData = styled.div`
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  .content_data{
    width: 512px;
   
  }
  .div_box_icon{
    text-align: center;
    /*
    img {
      width: 720px;
      height: 405px;
    }
    */
  }
  .head_text{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #101828;
    text-align: center;
    margin-top: 16px;
  }
  .sub_text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    text-align: center;
  }
`

const DivWarning = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #475467;
    background: #FFFFFF;
    padding: 1rem;
    .d-flex {
        display: flex;
        align-items: flex-start;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color : #344054;
      }
      .detail-warning{
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        color : #667085;
        margin: 5px 0px;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`

const DivModalWarningElearning = styled.div`
  padding: 24px 46px;
  /*min-width: 500px;*/
  width: 640px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
  .text_point_cpd{
    color: #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .link_goto{
    /*margin-bottom: 16px;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color : #0086C9;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .dialog-one_button {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
`

const DivKnowledge = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap : 10px;
  margin-top : 0px;

.status-badge {
    width: fit-content;
    padding: 4px 12px;
    
    display: flex;
    align-items: center;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    border-radius: 16px;
    color: #344054;
    background: #F8F9FC;
    border: 1px #D5D9EB solid;

    cursor: pointer;

    .status {
      font-size: 14px;
      margin-left: 2px;
      color: #344054;
      cursor: pointer;
    }

    &.active {
        color: #FFFFFF;
        background: #3E4784;
        border: 1px #3E4784 solid;
    }
  }
  .text-bold{
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: #344054;
  }
    .clearAll {
    width: fit-content;
    padding: 2px 6px;

    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    border-radius: 8px;
    color: #475467;
    background: #FFFFFF;
    border: 1px #FFFFFF solid;

    display: flex;
    align-items: center;

    cursor: pointer;

    .status {
      font-size: 18px;
      margin-right: 2px;
      color: #475467;
      
    }
  }
`

const Elearning = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = getAdminUser();

  const [TagList, setTagList] = useState([])
  const [ModalAddtag, setModalAddtag] = useState(false)
  const onAddtag = () => {
    setModalAddtag(true)
  }

  const handleAddedTags = (tagList) => {
    setTagList([...TagList,...tagList])
    setModalAddtag(false)
  }

  const handleRvTaglist = (inp_value) => {
    const this_TagList = TagList.filter((v) => v.value !== inp_value)
    setTagList(this_TagList)
  }

  const onClearAllTag = () => {
    setTagList([]);
  }

  const [ModalSelflearning, setModalSelflearning] = useState(false);
  const [OrgData, setOrgData] = useState({
    topic_name : '',
    website : null,
  })

  const [ModalWarning, setModalWarning] = useState(false);
  const [ModalConfirmCancle, setModalConfirmCancle] = useState(false)
  const [ElearningStill, setElearningStill] = useState({
    id : null,
    topic_name : '',
    sub_detail : '',
  })

  const [ModalConsent, setModalConsent] = useState(false)
  const [isDivEngineeringCourse, setisDivEngineeringCourse] = useState(false)
  const [isDivRead, setisDivRead] = useState(false)

  const onEngineeringCourse = () => {
    setisDivEngineeringCourse(isDivEngineeringCourse ? false : true)
  }
  const onRead = () => {
    setisDivRead(isDivRead ? false : true)
  }
  const inifilterData = {
    topic : null,
    organizations_id : null,
    sort : 'desc',
  }
  const [filterData , setfilterData] = useState(inifilterData);

  const [ElearningId, setElearningId] = useState(null)
  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [EngineeringCourseMainOption, setEngineeringCourseMainOption] = useState([])
  const [EngineeringCourse17Option, setEngineeringCourse17Option] = useState([])
  const [EngineeringCourseGeneralOption, setEngineeringCourseGeneralOption] = useState([])
  const [ReadOption, setReadOption] = useState([
    {
      text: 'ยังไม่เรียนรู้',
      value: 0,
      active_status : false,
    },
    {
      text: 'กำลังเรียนรู้',
      value: 1,
      active_status : false,
    },
    {
      text: 'เรียนรู้แล้ว',
      value: 2,
      active_status : false,
    },
  ])
  const [OrgOption, setOrgOption] = useState([])
  const [SortOption, setSortOption] = useState([
    {
      text: 'วันที่สร้างล่าสุด',
      value: 'desc',
      active_status : false,
    },
    {
      text: 'เวลาที่ใช้อ่าน น้อย -> มาก',
      value: 'read_asc',
      active_status : false,
    },
    {
      text: 'เวลาที่ใช้อ่าน มาก -> น้อย',
      value: 'read_desc',
    },
  ])

  useEffect(() => {
    dispatch(ApiGetEngineeringCourse()).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          const temp_EngineeringCourseMain = data_info?.data?.filter((v) => v.category_type == 'วิศวกรรม CPD');
          const temp_EngineeringCourse17 = data_info?.data?.filter((v) => v.category_type == '17 สาขา');
          const temp_EngineeringCourseGeneral = data_info?.data?.filter((v) => v.category_type == 'หมวดทั่วไป');
          const newEngineeringCourseMain= temp_EngineeringCourseMain?.map((v) => ({
            text: v?.name_th_th,
            value: v?.id,
            active_status : false,
          }))
          setEngineeringCourseMainOption([...newEngineeringCourseMain])
          const newEngineeringCourse17= temp_EngineeringCourse17?.map((v) => ({
            text: v?.name_th_th,
            value: v?.id,
            active_status : false,
          }))
          setEngineeringCourse17Option([...newEngineeringCourse17])
          const newEngineeringCourseGeneral= temp_EngineeringCourseGeneral?.map((v) => ({
            text: v?.name_th_th,
            value: v?.id,
            active_status : false,
          }))
          setEngineeringCourseGeneralOption([...newEngineeringCourseGeneral])
        }
    })
  }, [dispatch])

  
  useEffect(() => {
    const filter = {
      skip: 0,
      limit: 10000,
      not_coe : true
    }
    dispatch(ApiGetMemberORG(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            const newOrgOption =  data_info?.data?.map((v) => ({
              website : v?.website,
              text: v?.name_th,
              value: v?.id
            }))
            setOrgOption([...newOrgOption])
        }
    })
  }, [dispatch])

  const handleUpdateTabEngineeringCourseMain = (index, obj) => {
    const this_active_status = obj.active_status == true ? false : true;
    setEngineeringCourseMainOption((prevList) => {
      const newList = [...prevList];
      newList[index].active_status = this_active_status;
      return newList;
    });
  };
  const handleUpdateTabEngineeringCourse17 = (index, obj) => {
    const this_active_status = obj.active_status == true ? false : true;
    setEngineeringCourse17Option((prevList) => {
      const newList = [...prevList];
      newList[index].active_status = this_active_status;
      return newList;
    });
  };
  const handleUpdateTabEngineeringCourseGeneral = (index, obj) => {
    const this_active_status = obj.active_status == true ? false : true;
    setEngineeringCourseGeneralOption((prevList) => {
      const newList = [...prevList];
      newList[index].active_status = this_active_status;
      return newList;
    });
  };

  const handleUpdateTabRead = (index, obj) => {
    const this_active_status = obj.active_status == true ? false : true;
    setReadOption((prevList) => {
      const newList = [...prevList];
      newList[index].active_status = this_active_status;
      return newList;
    });
  };

  const [engineeringFill, setengineeringFill] = useState([])
  const [readFill, setreadFill] = useState([])
  
  const onClearAllEngineer = () => {
    setengineeringFill([]);
  }
  const handleChangeCheckBox = (e,inp_value,inp_text) => {
    if(e){
      setengineeringFill([...engineeringFill,{ value : inp_value , text : inp_text}])
    }else{
      const this_engineeringFill = engineeringFill.filter((v) => v.value !== inp_value)
      setengineeringFill(this_engineeringFill)
    }
    
  }

  const handleChangeCheckBoxRead = (e,inp_value,inp_text) => {
    if(e){
      setreadFill([...readFill,{ value : inp_value , text : inp_text}])
    }else{
      const this_readFill = readFill.filter((v) => v.value !== inp_value)
      setreadFill(this_readFill)
    }
    
  }


  const limit_data = 1000;
  const [total, settotal] = useState(0);
  const [page, setPage] = useState(1);
  const [ElearningData, setElearningData] = useState([]);

  const fetchData = useCallback(
    async (filter) => {
      dispatch(ApiGetElearningData(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          
         setElearningData(data_info.elearning_data)
         settotal(data_info.elearning_data.length)
        }else{
          setElearningData([])
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data
    }
    fetchData(filter)
  }, [fetchData, limit_data])

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      TagList : TagList,
      readFill : readFill,
      ...filterData
    }
    fetchData(filter)
    //---- 01/08/2567 กรณีค้นหาไม่ใช่แม่ข่ายของ จะแสดง Modal ให้ไปเรียนรู้เอง
    if(filterData.organizations_id){
      if(filterData.organizations_id != 94){
        const fillterORG = OrgOption.filter((obj) => obj.value == filterData.organizations_id);
        if(fillterORG.length > 0){
          setOrgData({
              topic_name : fillterORG[0].text,
              website : fillterORG[0].website ? fillterORG[0].website : '-',
          })
          setModalSelflearning(true);
        }
      }
    }
  }

  const onSortSearch = (v) => {
    setfilterData({ ...filterData, sort: v })
    const filter = {
      skip: 0,
      limit: limit_data,
      engineeringFill : engineeringFill,
      readFill : readFill,
      sort : v,
      topic : filterData.topic,
      /*organizations_id : filterData.organizations_id,*/
    }
    fetchData(filter)
  }
  const onClearSearch = () => {
    setfilterData(inifilterData)
    setengineeringFill([]);
    setTagList([]);
    setreadFill([]);
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
  }
  const onClearfilterName = () => {
    setfilterData({ ...filterData, topic:null })
  }
  const GotoInfo = (data) => {
    const data_to_api_check = {
      elearning_id : data.elearning_id
    };
    dispatch(ApiMemberCheckElearning(data_to_api_check)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          PageElearningInfo(data);
        }else if(data_info.code == 300){
          setElearningStill({
            id : data_info.elearning_id,
            topic_name : data_info.topic_name,
            sub_detail : data_info.sub_detail,
          })
          setModalWarning(true)
        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถเข้าดูบทความได้',
            message: '',
          })
          setFailModal(true)
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
    })


    
  }

  const PageElearningInfo = (data) => {
    if(data.elearning_register_id){
      navigate('/Activity/ElearningInfo/' + data.elearning_id)
    }else{
      navigate('/Activity/ElearningPreInfo/' + data.elearning_id)
    }
  }

  //---- Modal 
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const GotoElearningInfo = () => {
    if(ElearningStill.id){
      navigate('/Activity/ElearningInfo/' + ElearningStill.id)
    }
  }

  const IsModalConfirmCancle = () => {
    setModalWarning(false);
    setModalConfirmCancle(true);
  }
  const ProcessMemberCancleElearning = () => {
    setModalConfirmCancle(false);
    const data_to_api_check = {
      elearning_id : ElearningStill.id
    };
    dispatch(ApiMemberCancleElearning(data_to_api_check)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          setSuccessMessageModal({
            headline: 'ทำรายการสำเร็จ',
            message: 'สามารถเริ่มการเรียนรู้บทเรียนอื่นๆ ได้',
          })
          setSuccessModal(true)
        }else{
          setFailMessageModal({
            headline: 'ไม่สามารถยกเลิกบทความนี้ได้',
            message: '',
          })
          setFailModal(true)
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
    })
  }

  const GoToOrgWebsite = () => {
    if(OrgData.website != '-'){
      if(OrgData.website.includes('http')){
        window.open(`${OrgData.website}`, '_blank')
      }else{
        window.open(`https://${OrgData.website}`, '_blank')
      }

    }
    setModalSelflearning(false)
  }

  return (
    <Div>
      <div className="box_header">
          <div>
              <div className="content-title">
              CPD E-learning
              </div>
          </div>
          <div className="box_filter">
            <div className="header_filter_text" onClick={() => setModalConsent(true)}>
              อ่านกฏเกณฑ์เงื่อนไข
            </div>
          </div>
      </div>
      <div className="css_magin_top_24"></div>
      <div className="border_header"></div>
      <div className="css_magin_top_20"></div>
      <DivFilter>
        <div className="content-fillter">
          <div className="group_filter">
            <div>
                <Field className="field_EngineeringCourse" label="แขนงความรู้">
                  <ButtonOutline  onClick={onAddtag} className="button_EngineeringCourse">
                    {`เลือกแขนงความรู้ (${TagList ? TagList?.length : 0})`}
                  </ButtonOutline>
                </Field>
                <div className="box_dropdown_EngineeringCourse">
                    <div className={isDivEngineeringCourse ? 'dropdown_EngineeringCourse' : 'dropdown_EngineeringCourse_none'}>
                      {
                        /*
                        <div className="text_category">วิศวกรรมควบคุม</div>
                        */
                      }
                      

                      {EngineeringCourseMainOption?.map((item, index) => (
                        <div className="check_box_css">
                          <div>
                          <Checkbox checked={engineeringFill?.filter((v) => v.value == item.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item.value,item.text)} />
                          </div>
                          <div>
                            {item.text}
                          </div>
                        </div>
                      ))}  
                      {
                        /*
                                              <div className="text_category">วิศวกรรมไม่ควบคุม</div>
                      {EngineeringCourse17Option?.map((item_17, index_17) => (
                        <div className="check_box_css">
                          <div>
                            <Checkbox checked={engineeringFill?.filter((v) => v.value == item_17.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item_17.value,item_17.text)} />
                          </div>
                          <div>
                            {item_17.text}
                          </div>
                        </div>
                      ))} 
                      <div className="text_category">หมวดทั่วไป</div>
                      {EngineeringCourseGeneralOption?.map((item_general, index_general) => (
                        <div className="check_box_css">
                          <div>
                            <Checkbox checked={engineeringFill?.filter((v) => v.value == item_general.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBox(e,item_general.value,item_general.text)} />
                          </div>
                          <div>
                            {item_general.text}
                          </div>
                        </div>
                      ))} 
                        */
                      }


                    </div>
                </div>
            </div>
            <div>
              <Field className="field_read" label="สถานะการอ่าน">    
                  <ButtonOutline  onClick={onRead} className="button_read">
                    {`การอ่าน (${readFill ? readFill?.length : 0})`}
                  </ButtonOutline>
              </Field>
              <div className="box_dropdown_read">
                  <div className={isDivRead ? 'dropdown_read' : 'dropdown_read_none'}>
                    {ReadOption?.map((item, index) => (
                      <div className="check_box_css">
                        <div>
                        <Checkbox checked={readFill?.filter((v) => v.value == item.value).length > 0 ? true : false} onChange={(e) => handleChangeCheckBoxRead(e,item.value,item.text)} />
                        </div>
                        <div>
                          {item.text}
                        </div>
                      </div>
                    ))}   
                  </div>
                </div>     
            </div>

            <div>
              <Field className="field_keyword" label="ค้นหาด้วย keyword">    
                    <SearchText
                        placeholder="ค้นหาด้วย keyword"
                        value={filterData.topic}
                        onChange={(e) => setfilterData({ ...filterData, topic: e.target.value })}
                        onClear={onClearfilterName}
                    />
              </Field>
            </div>
          </div>
        </div>
        <div className="box_button_badge">
              <div className="box_badge">
                {
                  /*
                                  {engineeringFill?.length > 0  && (      
                  <DivBadgeEngineer>     
                    {engineeringFill?.map((item, index) => (                  
                      <div className="status-badge">
                        {item.text}
                        <IoMdClose  className="status" onClick={() => handleChangeCheckBox(false,item.value,item.text)}  />
                      </div>
                    ))} 
                    <div className="clearAll" onClick={onClearAllEngineer} >
                      <RiDeleteBinLine  className="status"   />
                      Clear all
                    </div>
                  </DivBadgeEngineer>
                )}   
                  */
                }
                {TagList?.length > 0  && ( 
                  <div>
                    <DivKnowledge>
                        {TagList?.map((item, index) => (                  
                            <div className={'status-badge'} >
                                <span className="text-bold">{item.knowledge_th}</span>-<span>{item.field_study_th}</span>
                                <IoMdClose  className="status" onClick={() => handleRvTaglist(item.value)} />
                            </div>
                        ))} 
                        <div className="clearAll" onClick={onClearAllTag} >
                          <RiDeleteBinLine  className="status"   />
                          Clear all
                        </div>
                    </DivKnowledge>
                  </div>
                )}

              </div>
              <div className="box_button">
                <div >
                    <Button onClick={onSearch}>
                      ค้นหา
                    </Button>
                </div>
                <div >
                  <ButtonSecondary  onClick={onClearSearch}>
                    ล้างการค้นหา
                  </ButtonSecondary>
                </div>
              </div>
          </div>
      </DivFilter>
      <div className="css_magin_top_20"></div>
      <div >
        <DivWarning>
          <div className="d-flex">
            <div className="icon-warning-container">
                <div className="box_icon" >
                    <PiWarningCircleBold size={25} />
                </div>                    
            </div>
            <div className="warning-message-container">
                <div className="headline-warning">
                    ท่านสามารถดูเนื้อหาการเรียนรู้ได้ครั้งละ 1 บทความเท่านั้น
                </div>
                <div className="detail-warning">
                    หากยังไม่จบการเรียนรู้ใน VDO ที่ท่านกำลังศึกษาอยู่จะไม่สามารถดู VDO ถัดไปได้
                </div>
            </div>
          </div>
        </DivWarning>
      </div>
      <div className="css_magin_top_32"></div>
      <div className="box_num_data">
        <div className="topic_engineering">
          เนื้อหาที่เหมาะกับคุณ ({total} รายการ) 
          {total == 0  && ( 
            <span className="css_no_data">
              ไม่พบรายการที่ค้นหา
            </span>
          )}  
        </div>
        {total > 0  && ( 
          <div>
            <Dropdown
              id={`dd_sort`}
              className="dd_sort"
              value={filterData.sort}
              optionList={SortOption}
              onChange={(v) => onSortSearch(v)}
              placeHolder={'เรียงตาม'}
              ValueplaceHolderIsNull={true}
            />
          </div>
        )} 
      </div>


      <div className="css_magin_top_32"></div>
      {total > 0  && (
        <div>
          <DivContent>
            {ElearningData?.map((item, index) => (
              <div className="box_data" onClick={() => PageElearningInfo(item)}>
                <div className="cover_img">
                  <img src={item.cover_img } />
                </div>
                <div className="box_engineer">
                  <div className="box_text_engineer">
                      { item.engineering_data?.map((item, index) => (
                            <span>
                                {index > 0 && (   
                                    <span>,</span>
                                )}
                                {item.knowledge_th + '-' + item.field_study_th}
                                
                            </span>
                        ))}   
                  </div>
                  {item.is_new_elearning  && (                
                    <div className="box_text_new">
                          <div className="css_icon">
                            <TiStarOutline size={20}   />
                          </div>
                          <div >New</div>
                    </div>
                  )}
                </div>
                <div className="box_text_topic">
                    {item.topic_name }              
                </div>
                <div className="box_organizations_name">
                  โดย {item.organizations_name }              
                </div>
                <div className="box_other">
                  <div >
                      <div className="content-topic-icon">                            
                          <div className="css_icon">
                            <LiaAwardSolid size={20}   />
                          </div>
                          <div >คะแนน CPD: <span className="text_input">{ Number(item.point).toFixed(2) }</span></div>
                      </div>
                  </div>
                  <div className="box_other_sub">
                      <div className="content-topic-icon">                            
                          <div className="css_icon">
                            <IoTimeOutline size={20}   />
                          </div>
                          <div >ระยะเวลาอ่าน: <span className="text_input">{item.time_read_display}</span></div>
                      </div>
                      <div className="content-topic-icon">                            
                          <div className="css_icon">
                            <FaListUl size={20}   />
                          </div>
                          <div ><span className="text_input">{item.is_exam_display}</span></div>
                      </div>
                  </div>             
                </div>
                <div className="box_user_status">
                  <div
                    className="status-badge"
                    style={{
                      color: item.elearning_user_badge_color?.color,
                      borderColor: item.elearning_user_badge_color?.border,
                      backgroundColor: item.elearning_user_badge_color?.background,
                    }}
                  >
                    <GrStatusGoodSmall 
                      className="status"
                      style={{
                        color: item.elearning_user_badge_color?.color,
                      }}
                    />
                    {item.elearning_user_status}
                  </div>
                </div>
              </div>
            ))}
          </DivContent>
        </div>
      )} 
      {total == 0  && (
        <div>
          <DivNoData>
            <div className="content_data">
              <div className="div_box_icon">
                <img src={iconSearch} />
              </div>  
              <div className="head_text">
                ไม่พบรายการที่ค้นหา
              </div>
              <div className="sub_text">
                กรุณาลองเปลี่ยนคำค้นหา
              </div>
            </div>
          </DivNoData>
        </div>
      )} 

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
          onClearSearch()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />

      <Modal open={ModalConsent} disableBackdropClick>
          <DivModalConsent>
            <div className="header_confirm">
              <div className="justify-content-end">
                <IoMdClose size={20} className="icon" onClick={() => setModalConsent(false)} />
              </div>
            </div>
            <div>
              <CpdConsent />
            </div>
          </DivModalConsent>
      </Modal>

      <Modal open={ModalWarning} disableBackdropClick>
          <DivModalWarningElearning>
              <div className="header_confirm">
                <div className="justify-content-center">
                  <img src={HappyIcon} alt="otp icon" />
                </div>
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center text_point_cpd">
                  ขณะนี้ท่านกำลังเรียนรู้
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.topic_name}
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.sub_detail}
              </div>
              <div className="justify-content-center">
                กรุณาจบการเรียนรู้ก่อนเริ่มบทเรียนอื่นๆ
              </div>
              <div className="css_magin_top_22"></div>
              <div className="dialog-button">
                <ButtonSecondary onClick={IsModalConfirmCancle} >ต้องการยกเลิกบทเรียนนี้</ButtonSecondary>
                <Button onClick={GotoElearningInfo}>ไปจบการเรียนรู้</Button>           
              </div>
          </DivModalWarningElearning>
      </Modal>
      <Modal open={ModalConfirmCancle} disableBackdropClick>
          <DivModalWarningElearning>
              <div className="header_confirm">
                <div className="justify-content-center">
                  <img src={warningIcon} alt="otp icon" />
                </div>
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center text_point_cpd">
                  ยืนยันการยกเลิกการเรียนรู้
              </div>
              <div className="css_magin_top_22"></div>
              <div className="justify-content-center">
                {ElearningStill.topic_name}
              </div>
              <div className="justify-content-center">
                หากยกเลิกการเรียนรู้ก่อนจบการเรียนรู้ คุณจะไม่ได้รับคะแนน
              </div>
              <div className="css_magin_top_22"></div>
              <div className="dialog-button">
                <ButtonSecondary onClick={() => setModalConfirmCancle(false)} >กลับ</ButtonSecondary>
                <Button onClick={ProcessMemberCancleElearning}>ยืนยันและยกเลิก</Button>           
              </div>
          </DivModalWarningElearning>
      </Modal>

      <Modal open={ModalSelflearning} disableBackdropClick>
          <DivModalWarningElearning>
              <div className="header_confirm">
                  <div>
                    <img src={successIcon} alt="otp icon" />
                  </div>
                  <div>
                    <IoMdClose size={20} className="icon" onClick={() => setModalSelflearning(false)} />
                  </div>
              </div>
              <div className="css_magin_top_22"></div>
              <div className="text_point_cpd">
                เรียนรู้ด้วยตนเองกับองค์กรแม่ข่าย
              </div>
              <div >
                องค์กรแม่ข่าย : {OrgData.topic_name}
              </div>
              <div >
                Website : {OrgData.website}
              </div>
              <div className="css_magin_top_22"></div>
              <div >
                คลิกเพื่อเข้าเรียนรู้ด้วยตนเองจากองค์กรแม่ข่าย 
              </div>
              <div >
                หลังเรียนรู้จบ ท่านสามารถ เข้าระบบเพื่อรับคะแนน CPD ได้ที่เมนู  
              </div>
              <div >
                ‘บันทึกคะแนนกิจกรรมด้วยตนเอง’ 
              </div>
              <div className="css_magin_top_22"></div>
              {OrgData.website != '-'  ? (
                <div className="dialog-button">
                  <ButtonSecondary onClick={() => setModalSelflearning(false)} >กลับ</ButtonSecondary>                
                  <Button onClick={GoToOrgWebsite}>คลิกเพื่อเข้าสู่เว็ปไซด์</Button>                
                </div>
              ) : (
                <div className="dialog-one_button">
                  <Button onClick={() => setModalSelflearning(false)}>กลับ</Button>   
                </div>
              )
              }
          </DivModalWarningElearning>
      </Modal>

      <Modal open={ModalAddtag} disableBackdropClick>
          <ModalKnowledge
            onClose={() => setModalAddtag(false)} 
            onSubmit={(e) => handleAddedTags(e)}
            isNewTag={false}
          />
      </Modal>
    </Div>
  )
}

export default Elearning
