import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'

import {  getAdminUser } from '../utils/common'
import { Api_GETCpdConsent } from '../redux/actions/master'

import Button from '../components/form/button/Button'
import TextEditorV2 from '../components/widget/element/TextEditorV2'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'

import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'

const Div = styled.div`
  font-size: 14px;

  .text-editor {
    margin-top: 32px;
    /*height: 500px;*/
    /*height: 50vh;*/
    height: ${(props) => props.height || 'auto'};
    background: #FFFFFF;
    overflow-y: auto;
  }
  .ql-container {
    /*min-height: 45px;*/
    font-family: inherit;
  }
  .ql-tooltip{
    display : none;
  }
  .ql-clipboard{
    display : none;
  }
  .ql-editor{
    padding: 0px 0px;
    font-size: 18px;
    h2{
      font-size: 20px;
    }
    p{
      margin : 0px;   
    }
  }

  .css_magin_top_16 {
    margin-top: 16px;
  }
  .fill_box{
    /*overflow-y: auto;*/
  }
  .headline{
    color : #101828;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }
  .text_company{
    color : #A91F23;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }
`



const CpdConsent = ({
  height = '500px'
}) => {
    const dispatch = useDispatch()
    const user = getAdminUser()
    const fix_id_consent = 1;
    const [DataInfo, setDataInfo] = useState({
        headline : null,
        detail : null
    })
    const initMessageModal = {
        headline: '',
        message: '',
    }
    const [successModal, setSuccessModal] = useState(false)
    const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
    const [failModal, setFailModal] = useState(false)
    const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
    useEffect(() => {

        dispatch(Api_GETCpdConsent(fix_id_consent)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info.id){
                    setDataInfo(data_info);
                }
            }
        })
    }, [dispatch])

  return (
    <Div height={height}>
        <div className="fill_box">
            
            <div className="headline">
                {DataInfo.headline}
            </div>
            <div className="text-editor" >
                <ReactQuill  
                value={DataInfo?.detail} 
                readOnly={true} 
                theme={'bubble'} 
                modules={{
                  clipboard: {
                    matchVisual: false
                  }
                }}
                />
            </div>  
        </div>
        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            description={successMessageModal.message}
            textYes='ตกลง'
        />
        <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
            setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.message}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default CpdConsent
