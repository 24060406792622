import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'
import ReactQuill from 'react-quill'
import ReactPlayer from 'react-player'

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay } from '../../utils/common'
import { ApiOrgElearning_profile,Api_Update_Elearning_Register,ApiMemberEndExam } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import RadioButton from '../../components/form/RadioButton'
import Modal from '../../components/common/Modal'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'


import iconNext from '../../assets/images/icon-next-white.svg'
import iconBack from '../../assets/images/icon-back.svg'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconFile from '../../assets/images/pdf-icon.png'
import crossIcon from '../../assets/images/crossIcon.png'
import warningIcon from '../../assets/images/warning-icon.png'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FiBook } from "react-icons/fi";
import { AiOutlinePushpin } from "react-icons/ai";
import { LiaAwardSolid } from "react-icons/lia";
import { IoTimeOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoMdCheckboxOutline } from "react-icons/io";
import { IoMdClose } from 'react-icons/io'
import { LuFileCheck } from "react-icons/lu";
import { FaListUl } from "react-icons/fa";
import { MdOutlineCalculate } from "react-icons/md";
import { GrStatusGoodSmall } from 'react-icons/gr'
import { FaArrowRight } from "react-icons/fa6";
import HappyIcon from '../../assets/images/Haapy-icon.png'
import NertralIcon from '../../assets/images/Nertral-icon.png'

const Div = styled.div`
  margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .box_header_right{
    color : #475467;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    .fill_text{
      color : #475467;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }
    .status_learning{
      display: flex;
      gap: 10px;
    }
    .box_user_status{
      .status-badge {
        width: fit-content;
        padding: 2px 6px;
        text-align: center;
        font-weight: 500;
        border-radius: 16px;
        color: #475467;
        background: #f5f5f4;
        border: 1px #e7e5e4 solid;
        .status {
          font-size: 0.6rem;
          margin-right: 0.2rem;
          color: #475467;    
        }
      }
    }
  }
  .css_magin_top_32 {
    margin-top: 32px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .button_EndExam {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
`
const DivCondition = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
padding: 24px;
.other_box{
  display: grid;
  grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
  gap: 2%;
  align-items: start;
  /*margin-top: 12px;*/
}
.other_box_sub{
  display: grid;
  grid-template-columns: repeat(2, 30% [col-start] 65% [col-end]);
  gap: 2%;
  align-items: start;
  /*margin-top: 12px;*/
}
.content-topic-icon{
  display: flex;
  align-items: center;
  /*line-height: 0px;*/
  gap: 10px;
  color : #667085;
}
.css_icon{
  display: flex;
  align-items: center;
}
.text_normal{
  color : #667085;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.text_input{
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.css_magin_top_12 {
  margin-top: 12px;
}
`
const DivDataExam = styled.div`
  margin-top: 16px;
  .topic_css{
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .ql-container {
    /*min-height: 45px;*/
    font-family: inherit;
  }
  .ql-tooltip{
    display : none;
  }
  .ql-clipboard{
    display : none;
  }
  .ql-editor{
    padding: 0px 0px;
    font-size: 18px;
    h2{
      font-size: 20px;
    }
    p{
      margin : 0px;   
    }
  }
  .exam{
    display: grid;
    grid-template-columns: repeat(2, 8% [ col-start ] 90% [ col-end ]);
    gap: 2%;
    /* ST-TimeLine 03/03/2024 */
    .question_number_font{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color : #344054;
      &.active{
        color : #A91F23;
      }
    }
    ul.timeline_question{
      list-style-type: none;
      position: relative;
    }
    ul.timeline_question:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }
    ul.timeline_question > li {
      margin: 20px 0;
      padding-left: 10px;
    }
    ul.timeline_question > li:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;        
        width: 24px;
        height: 24px;
        background-color: #EAECF0;
        box-shadow: 0px 0px 5px 1px #FFFFFF,inset 0 0 0 1px #EAECF0, inset 0 0 0 7px #FFFFFF, inset 0 0 0 8px #EAECF0;

    }
    ul.timeline_question > li.question_number_active:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;
        width: 24px;
        height: 24px;
        background-color: #A91F23;
        box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2, inset 0 0 0 8px #A81E23;
    }
    ul.timeline_question > li.question_number_answer:before {
      cursor: pointer;
      content: "✓";
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      left: 18px;
      z-index: 400;
      text-align: center;
      width: 24px;
      height: 24px;
      color: #A91F23;

      background-color: #fef3f2;
      box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2;

      /*
      background-color: #A91F23;
      box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2, inset 0 0 0 8px #A81E23;
      */
      
  }

    /* ED-TimeLine 03/03/2024 */


    .question_content{

      .question_fix{
        /*padding: 0px 15px;*/
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 16px;
      }
      .question_img{
        margin-top: 16px;
        img {
          width: 450px;
          height: 300px;
        }
      }
      .answer_list{
        margin-top: 48px;
        
        .text_other{
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 16px;
        }
        .box_choice{
          /*
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          gap: 10px;
          */
          .answer_box{
            display: grid;
            grid-template-columns: repeat(2, 5% [ col-start ] 70% [ col-end ]);
            gap: 10px;
            border-radius: 8px;
            border: 1px solid #EAECF0;
            background: #FFFFFF;
            padding: 16px;
            width: 100%;
            margin: 16px 0px;
        
            &.correct_answer {
                border: 1px solid #A91F23;
                background: #FEF3F2;
            }
            .radio_css{
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              display: flex;
            }
            .choice_content{
              margin-top: 9px;
            }
            .choice_img{
              margin-top: 16px;
              img {
                width: 330px;
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
  .box_button{
    margin-top: 16px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
`

const DivModalConfirm = styled.div`
  padding: 24px 46px;
  /*min-width: 500px;*/
  width: 640px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color : #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .link_goto{
    /*margin-bottom: 16px;*/
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color : #0086C9;
    text-decoration-line: underline;
    cursor: pointer;
  }
`


const ElearningExam = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { id } = useParams()
  const navigate = useNavigate()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [QuestionIndex, setQuestionIndex] = useState(0)
  const [DataExam, setDataExam] = useState(null)

  useEffect(() => {
    const sent_data_to_api = {
        elearning_id : id,
    };
    dispatch(ApiOrgElearning_profile(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
            if(data_info.data_info.exam_data){
                setDataExam(data_info.data_info.exam_data[0])
                setQuestionIndex(0)
            }
            setDataInfo(data_info.data_info);
            setisOpenData(true)
        }
      }
    })

}, [dispatch])

 const goto_question = (question_index) => {
    setDataExam(DataInfo?.exam_data[question_index])
    setQuestionIndex(question_index)
  }


  const onChangeRadio = (index_question,index_choice,event) => {
    const { value } = event.target;
    const newState = JSON.parse(DataExam?.choices)?.map((obj, index) => {
        if (index === index_choice) {
            return { ...obj, is_user_answer: true  }
        }else{
            return { ...obj, is_user_answer: false  }
        }
    })
    setDataExam({ ...DataExam, choices: JSON.stringify(newState) });

    //console.log(JSON.stringify(DataInfo))
    let data_exam = [];
    const newStateexam_data = DataInfo?.exam_data.map((obj, index) => {
      if (index === index_question) {
        data_exam.push(
          {
            question_id: obj.question_id,
            question_code: obj.question_code,
            question: obj.question,
            question_image: obj.question_image,
            is_random_answer: obj.is_random_answer,
            choices : JSON.stringify(newState)
          }
        )
        return { ...obj, choices: JSON.stringify(newState)  }          
      }else{
        data_exam.push(
          {
            question_id: obj.question_id,
            question_code: obj.question_code,
            question: obj.question,
            question_image: obj.question_image,
            is_random_answer: obj.is_random_answer,
            choices : obj.choices
          }
        )
        return obj
      }
      
    })
    //console.log('---DataInfoDataInfo----');
    //console.log(JSON.stringify(DataInfo));
    const newDataInfo = {...DataInfo , exam_data : newStateexam_data}
    //console.log('---newDataInfo----');
    //console.log(JSON.stringify(newDataInfo));
    setDataInfo(newDataInfo);

    //---- ส่ง API อัพเดตการทำข้อสอบ
    const data_to_api = {
      exam_answers_data : JSON.stringify(data_exam)
    };
    
    dispatch(Api_Update_Elearning_Register(DataInfo.elearning_register_id,data_to_api))

  }
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [failModal, setFailModal] = useState(false)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [ModalConfirm, setModalConfirm] = useState(false)
  const [ModalConfirmSuccess, setModalConfirmSuccess] = useState(false)
  const [DataInfoReturn, setDataInfoReturn] = useState({
    percent_exam : 0,
    point_exam : 0,
    exam_status : 2
  })
  const [NumAnswer, setNumAnswer] = useState(0)
  const ConfirmDialog = () => {
    let num_chose_choise = 0;
    for(const item_exam_data of DataInfo?.exam_data){
      const nuser_answer = JSON.parse(item_exam_data.choices)?.filter((v) => v.is_user_answer == true).length;
      num_chose_choise = parseInt(num_chose_choise) + parseInt(nuser_answer);
    }
    setNumAnswer(num_chose_choise)
    setModalConfirm(true)
  }
  const closeModalConfirm = () => {
    setModalConfirm(false);
  }
  const closeModalConfirmSuccess = () => {
    setModalConfirmSuccess(false);
  }
  const ApproveProcess = () => {
    setModalConfirm(false);
    const data_to_api = {
      elearning_id : id
    };
    
    dispatch(ApiMemberEndExam(data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.code == 200){
          setDataInfoReturn({
            percent_exam : Number(data_info.percent_exam).toFixed(2),
            point_exam : data_info.point_exam,
            exam_status : data_info.exam_status
          })
          setModalConfirmSuccess(true);
        }else{
          setFailMessageModal({
            headline: data_info.message,
            message: '',
          })
          setFailModal(true)
        }
      }else{
        setFailMessageModal({
          headline: 'internal server error',
          message: '',
        })
        setFailModal(true)
      }
    })
    
  }

  const gotoPageBack = () => {
    navigate('/Activity/Elearning')
  }
  const gotoMyCPD = () => {
    navigate('/Member/CpdPoint')
  }
  return (
    <Div>
      {isOpenData == true && ( 
        <div>
          <div className="box_header">
              <div>
                  <div className="content-title">
                    ทดสอบความรู้หลังการอบรม
                  </div>
              </div>
              <div>
                <Button onClick={() => ConfirmDialog()} className="button_EndExam">
                  ส่งคำตอบ
                </Button>
              </div>
          </div>
          <div className="css_magin_top_32"></div>
          <div className="border_header"></div>
          <div className="css_magin_top_32"></div>
          <DivCondition>
              <div className="other_box">
                <div >
                    <div className="other_box_sub">
                        <div className="content-topic-icon">                                
                            <div className="css_icon">
                            <FaListUl size={20}   />
                            </div>
                            <div className="text_normal">จำนวนข้อสอบ</div>
                        </div>
                        <div className="text_input">{DataInfo?.exam_data ? DataInfo?.exam_data.length : '0'} ข้อ</div>
                    </div>
                    <div className="css_magin_top_12"></div>
                    <div className="other_box_sub">
                        <div className="content-topic-icon">                               
                            <div className="css_icon">
                                <FaRegCircleCheck size={20}   />
                            </div>
                            <div className="text_normal">เงื่อนไขการสอบผ่าน</div>
                        </div>
                        <div className="text_input">{DataInfo.condition_exam_pass}</div>
                    </div> 
                    <div className="css_magin_top_12"></div>
                    <div className="other_box_sub">
                        <div className="content-topic-icon">                               
                            <div className="css_icon">
                                <MdOutlineCalculate size={20}   />
                            </div>
                            <div className="text_normal">การนับคะแนน</div>
                        </div>
                        <div className="text_input">
                            <div className="text_input">เกณฑ์มาตรฐาน: </div>
                            <div>
                                <ul className="css_margin_0 text_normal">
                                    <li>ตอบถูก = {DataInfo?.valid_answer_point} คะแนน</li>
                                    <li>ตอบผิด = {DataInfo?.invalid_answer_point} คะแนน</li>
                                    <li>ไม่ตอบ = {DataInfo?.no_answer_point} คะแนน</li>
                                </ul>
                            </div>               
                        </div>
                    </div>
                </div>
              </div>
          </DivCondition>
          <div className="css_magin_top_32"></div>
          <DivDataExam >
            <div className="exam">
                <div >
                <ul className="timeline_question">
                    {DataInfo?.exam_data?.map((item, index) => (
                    <li className={`${index == QuestionIndex ? 'question_number_active' : JSON.parse(item.choices)?.filter((v) => v.is_user_answer == true).length > 0 ? 'question_number_answer' : ''}`} onClick={() => goto_question(index)} >
                        <div className={`question_number_font ${index == QuestionIndex ? 'active' : ''}`}>{index + 1}.</div>
                    </li>
                    ))}
                </ul>
                </div>

                <div className="question_content">
                    <div className="question_fix">ข้อที่ {QuestionIndex + 1}</div>
                    <div >
                    <ReactQuill  
                    value={DataExam?.question} 
                    readOnly={true} 
                    theme={'bubble'} 
                    modules={{
                      clipboard: {
                        matchVisual: false
                      }
                    }}
                    />
                    </div>  
                    {DataExam?.question_image && (
                    <div className="question_img">
                        <img src={DataExam?.question_image } />
                    </div>
                    )}
                    <div className="answer_list">
                    <div className="text_other">
                        ตัวเลือก
                    </div>
                    <div className="box_choice">
                        {JSON.parse(DataExam?.choices)?.map((item_choice, index_choice) => (
                            <div className={`answer_box ${item_choice.is_user_answer ? 'correct_answer' : ''}`}>
                                <div className="radio_css">
                                <RadioButton 
                                    optionList={[{text: `${index_choice + 1}.`,value: true,}]} 
                                    value={item_choice.is_user_answer} 
                                    handleChange={(e) => onChangeRadio(QuestionIndex,index_choice,e)}
                                />                                             
                                </div>
                                <div className="choice_content">
                                <div >
                                                                            
                                    <div>
                                    <ReactQuill  
                                    value={item_choice.choice} 
                                    readOnly={true} 
                                    theme={'bubble'} 
                                    modules={{
                                      clipboard: {
                                        matchVisual: false
                                      }
                                    }}
                                    />
                                    </div>
                                    
                                </div>
                                {item_choice.choice_image && (
                                    <div className="choice_img">
                                        <img src={item_choice.choice_image } />
                                    </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                    <div className="box_button">
                    {
                        QuestionIndex > 0 ? (
                        <ButtonSecondary append={<img src={iconBack} />} onClick={() => goto_question(QuestionIndex - 1)}>
                            ย้อนกลับ
                        </ButtonSecondary>
                        ) : <div></div>
                    }  
                    {
                        QuestionIndex < (DataInfo?.exam_data.length - 1) ? (
                        <Button prepend={<img src={iconNext} />} onClick={() => goto_question(QuestionIndex + 1)}>
                            ข้อถัดไป
                        </Button>
                        ) : <div></div>
                    }
                    </div>
                </div>
            </div>
          </DivDataExam>  
        </div>
      )}

      {/* ModalConfirm */}
      <Modal open={ModalConfirm} disableBackdropClick>
              <DivModalConfirm>
                <div className="header_confirm">
                  <div className="justify-content-end">
                    <IoMdClose size={20} className="icon" onClick={closeModalConfirm} />
                  </div>
                  <div className="justify-content-center">
                    <img src={warningIcon} alt="otp icon" />
                  </div>
                  <div className="justify-content-center modal_text_bold">
                    ตรวจสอบข้อมูลก่อนส่งคำตอบ
                  </div>
                </div>
                <div className="css_magin_top_32"></div>
                <div>
                  <div>
                    โปรดตรวจสอบให้แน่ใจว่าท่านได้ทำแบบทดสอบครบทุกข้อแล้ว โดยเมื่อส่งคำตอบแล้ว 
                  </div>
                  <div>
                    ท่านจะได้ทราบผลคะแนน CPD ที่ได้รับในครั้งนี้ทันที
                  </div>
                  <div className="css_magin_top_23"></div>
                  <div>
                    สรุปข้อมูลการตอบคำถาม
                  </div>
                  <div className="box_answer">
                    <div className="box_answer_bold">* ตอบแล้ว {NumAnswer} จาก {DataInfo?.exam_data?.length} ข้อ</div>
                    <div>
                      <FaRegCircleCheck size={20}   />
                    </div>
                    <div>
                      {NumAnswer == DataInfo?.exam_data?.length ? 'ตอบครบทุกข้อแล้ว' : 'ยังตอบไม่ครบ'} 
                    </div>
                  </div>
                  <div className="css_magin_top_23"></div>
                  <div>
                    หากมั่นใจแล้วกรุณากด “ส่งคำตอบ” ก่อนหมดเวลา หากต้องการกลับไปแก้ไข กรุณากด “กลับสู่หน้าทำแบบทดสอบ”
                  </div>
                </div>    
                <div className="css_magin_top_23"></div>
                <div className="border_header"></div>
                <div className="css_magin_top_23"></div>
                <div className="dialog-button">
                  <ButtonSecondary onClick={closeModalConfirm} >กลับสู่หน้าทำแบบทดสอบ</ButtonSecondary>
                  <Button onClick={ApproveProcess}>ส่งคำตอบ</Button>
                </div>
              </DivModalConfirm>
      </Modal>

      {/* ModalPoint */}
      <Modal open={ModalConfirmSuccess} disableBackdropClick>
              <DivModalConfirm>
                <div className="header_confirm">
                  <div className="justify-content-end">
                    <IoMdClose size={20} className="icon" onClick={closeModalConfirmSuccess} />
                  </div>
                  <div className="justify-content-center">
                    <img src={ DataInfoReturn.exam_status == 3 ? HappyIcon : NertralIcon} alt="otp icon" />
                  </div>
                  <div className="justify-content-center modal_text_bold">
                    {DataInfoReturn.exam_status == 3 ? 'ยินดีด้วย! คุณสอบผ่าน ด้วยคะแนน ' + DataInfoReturn.percent_exam + '%' : 'คุณสอบไม่ผ่าน ด้วยคะแนน ' + DataInfoReturn.percent_exam + '%'}
                  </div>
                </div>
                <div className="css_magin_top_22"></div>
                <div className="justify-content-center text_point_cpd">
                  ได้รับ {DataInfoReturn.point_exam} คะแนน CPD
                </div>
                <div className="css_magin_top_22"></div>
                {
                  /*                  
                <div>
                  {DataInfoReturn.exam_status == 3 ? 'คุณได้รับคะแนนเต็มจากหัวข้อนี้ และสามารถตรวจสอบประวัติการได้รับคะแนนได้ที่เมนู' : 'คุณสามารถทำแบบทดสอบใหม่อีกครั้งในภายหลังได้ เพื่อได้รับคะแนนเต็มจากหัวข้อนี้  และสามารถตรวจสอบประวัติการได้รับคะแนนได้ที่เมนู'}
                </div>
                  */
                }
                {DataInfoReturn.exam_status == 3 && (   
                  <div>
                    คุณได้รับคะแนนเต็มจากหัวข้อนี้  
                    <div>
                      และสามารถตรวจสอบประวัติการได้รับคะแนนได้ที่เมนู
                      <span className="link_goto" onClick={gotoMyCPD}>
                        ตรวจสอบคะแนน My CPD
                      </span>
                    </div>
                  </div>
                  
                )} 
                {DataInfoReturn.exam_status == 2 && (   
                  <div>
                    คุณสามารถทำแบบทดสอบใหม่อีกครั้งในภายหลังได้ เพื่อได้รับคะแนนเต็มจากหัวข้อนี้   
                    <div>
                      และสามารถตรวจสอบประวัติการได้รับคะแนนได้ที่เมนู
                      <span className="link_goto" onClick={gotoMyCPD}>
                        ตรวจสอบคะแนน My CPD
                      </span>
                    </div>
                  </div>
                )} 
                <div className="css_magin_top_23"></div>
                <div className="justify-content-center">
                  <Button onClick={gotoPageBack}>กลับสู่หน้าหลัก</Button>
                </div>
              </DivModalConfirm>
      </Modal>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ElearningExam
