import { combineReducers } from 'redux'
import {
  GET_TYPE_ACTIVITY_CPD,
  GET_BRANCH_ACTIVITY_CPD,
  GET_CRITERIA_CPD,
  GET_BRANCH_ENGINEER,
  GET_MEMBER_COE,
} from '../actionTypes'

const initialState = {
  typeActivityCPDOptions: [],
  branchActivityCPDOptions: [],
  criteriaCPDOptions: [],
  branchEngineerCPDOptions: [],
  isLoading: false,
  state: '',
}

const masterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TYPE_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case GET_TYPE_ACTIVITY_CPD.SUCCESS: {
      const { data } = action
      return { ...state, typeActivityCPDOptions: data, isLoading: false }
    }
    case GET_TYPE_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false }
    }
    case GET_BRANCH_ACTIVITY_CPD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case GET_BRANCH_ACTIVITY_CPD.SUCCESS: {
      const { data } = action
      return { ...state, branchActivityCPDOptions: data, isLoading: false }
    }
    case GET_BRANCH_ACTIVITY_CPD.FAILURE: {
      return { ...state, isLoading: false }
    }
    case GET_CRITERIA_CPD.REQUEST: {
      return { ...state, isLoading: true }
    }
    case GET_CRITERIA_CPD.SUCCESS: {
      const { data } = action
      return { ...state, criteriaCPDOptions: data, isLoading: false }
    }
    case GET_CRITERIA_CPD.FAILURE: {
      return { ...state, isLoading: false }
    }
    case GET_BRANCH_ENGINEER.REQUEST: {
      return { ...state, isLoading: true }
    }
    case GET_BRANCH_ENGINEER.SUCCESS: {
      const { data } = action
      return { ...state, branchEngineerCPDOptions: data, isLoading: false }
    }
    case GET_BRANCH_ENGINEER.FAILURE: {
      return { ...state, isLoading: false }
    }
    case GET_MEMBER_COE.REQUEST: {
      return { ...state, isLoading: true }
    }
    case GET_MEMBER_COE.SUCCESS: {
      return { ...state, isLoading: false }
    }
    case GET_MEMBER_COE.FAILURE: {
      return { ...state, isLoading: false }
    }
    default:
      return state
  }
}

export default combineReducers({
  masterReducer,
})
