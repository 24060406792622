import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import styled from 'styled-components'

import { ApiExternalEventApprove } from '../../redux/actions/events'

import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

const Div = styled.div`
  padding: 96px;

  .mr-12-px {
    margin-right: 0.75rem;
  }

  .highlight-error {
    color: var(--Primary-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  .error-headline {
    color: var(--Gray-900);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .error-desc {
    color: var(--Gray-600);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;
  }
  .button-container {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
`

const DivModalConsent = styled.div`
  padding: 32px 32px;
  /*min-width: 500px;*/
  width: 1073px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #475467;
  .icon {
    cursor: pointer;
  }

  .header_confirm{

  }
  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .justify-content-end {
    display: flex;
    justify-content: flex-end;
  }
  .justify-content-space-between {
    display: flex;
    justify-content: space-between;
  }
  .modal_text_bold{
    color : #101828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .modal_text_normal{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .modal_text_input{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_22{
    margin-top: 22px;
  }
  .css_magin_top_23{
    margin-top: 23px;
  }
  .css_magin_top_32{
    margin-top: 32px;
  }
  .content_confirm{
    display: grid;
    grid-template-columns: repeat(2, 40% [col-start] 60% [col-end]);
    gap: 10px;
    align-items: start;
    margin: 8px 0px;
  }
  .css_margin_0{
    margin:0px
  }
  .icon-check{
    color : var(--Base-Font-Focus-Color);
  }
  .content-topic{
    display: flex;
    /*
    justify-content: center;
    */
    align-items: center;
    line-height: 0px;
    gap: 10px;
  }
  .modal_text_topic_name{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .box_answer {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color : #079455;
    .box_answer_bold{
      color : #101828;
    }


  }
  .dialog-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }

  }
  .text_point_cpd{
    color : #A91F23;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .d-flex {
    display: flex;
    gap: 10px;
  }
`

function EventConfirm() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const initMessageModal = {
    headline: '',
    subtitle: '',
    description: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failModal, setFailModal] = useState(false)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  useEffect(() => {
    const data_to_api = {
        user_activity_point_id : id,
    };
    dispatch(ApiExternalEventApprove(data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.code == 200){
            setSuccessMessageModal({
                headline: 'ลงทะเบียนสำเร็จ',
                subtitle: data_info.full_name,
                description: 'ขอบคุณที่สนใจเข้าร่วมกิจกรรม',
            })
            setSuccessModal(true);
          }else{
            setFailMessageModal({
                headline: 'ขออภัยไม่สามารถยืนยันสิทธิ์ได้',
                description: data_info.message,
              })
              setFailModal(true)
          }
        }else{
          setFailMessageModal({
            headline: 'internal server error',
            description: '',
          })
          setFailModal(true)
        }
    })
  }, [dispatch])

  return (
    <Div>
      <div className="highlight-error">ขณะนี้กำลังทำรายการ</div>
      <div className="error-headline">กรุณารอสักครู่</div>
      <div className="error-desc">กรุณาอย่าปิด browser จนกว่าจะทำรายการสำเร็จ</div>

        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={() => setSuccessModal(false)}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
        <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventConfirm
