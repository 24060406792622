import styled from 'styled-components'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {  DateDisplay } from '../../utils/common'
import { ApiElearning_profile } from '../../redux/actions/elearning'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import RadioButton from '../../components/form/RadioButton'
import Field from '../../components/form/Field'


import iconNext from '../../assets/images/icon-next-white.svg'
import iconBack from '../../assets/images/icon-back.svg'

import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconFile from '../../assets/images/pdf-icon.png'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  }
  .content_header {
    /*padding: 14px 14px;*/
    padding-top: 48px;
    padding-bottom: 14px;
    padding-left: 80px;
    padding-right: 80px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 500;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 140px;
    padding-bottom: 20px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .content{
    margin:10px 0px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .css_magin_top_16{
    margin-top: 16px;
  }
  .topic_css{
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
  }
  .detail_css{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .cover_img{
    text-align: center;
    img {
        width: 1600px;
        height: 480px;
    }
  }

  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #A91F23;
    background: #FEF3F2;
    border: 1px #FECDCA solid;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .status-badge-engineer {
    width: fit-content;
    padding: 2px 6px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;
    color: #A91F23;
    background: #FFF;
    border: 1px #FECDCA solid;
    display: flex;
    gap: 2px;
    align-items: center;
  }
  .css_box_date{
    display: flex;
    gap: 48px;
    .topic{
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        color: #A91F23;
    }
    .detail{
        margin-top : 12px;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: #101828;
    }
  }
`

const DivTab = styled.div`
width: 100%;
margin-bottom: 0rem;
margin-top: 0rem;
display: flex;
/*border-bottom: 1px solid var(--Tab-Border-Default);*/

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--Tab-Bg-Default);
    /*border-bottom: 0px solid var(--Tab-Border-Default);*/
    border-bottom: 2px solid var(--Tab-Border-Default);
    color: var(--Tab-Font-Default);  
    font-size: 14px;
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Tab-Border-Current) !important;
    color: var(--Tab-Font-Current); 
    background-color: var(--Tab-Bg-Current);
    /*font-family: 'Inter','Noto Sans Thai';*/
    font-family: inherit;
}
`

import ReactQuill from 'react-quill'
import ReactPlayer from 'react-player'
const DivDataContent = styled.div`
  
  /*padding: 20px 250px;*/
  
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  
  .content_data{
    
    width: 720px;
    
  }
  .content_margin_top_14{
    margin-top: 14px;
  }
  /* ST ส่วนของ TextEditor */
  .ql-container {
    /*min-height: 45px;*/
    font-family: inherit;
  }
  .ql-tooltip{
    display : none;
  }
  .ql-clipboard{
    display : none;
  }
  .ql-editor{
    padding: 0px 0px;
    font-size: 18px;
    h2{
      font-size: 20px;
    }
    p{
      margin : 0px;   
    }
  }

  /* ED ส่วนของ TextEditor */

  .div_box_type_content_text{
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 14px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .div_box_type_content_vdo{
    display: flex;
    justify-content: center;
  }
  .div_box_type_content_vdo_sub_content{
    margin-top: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .div_box_type_content_img{
    display: flex;
    justify-content: center;
    padding: 14px 0px;
  }
  .div_box_type_content_img_head_content{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 16px;
  }
  .div_box_type_content_img_sub_content{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 16px;
  }
  .div_box_type_content_img_url{
    text-align: center;
    img {
        width: 720px;
        height: 405px;
    }
  }
  .div_box_type_content_pdf{
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 0px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    .icon-wrapper{
      width: 32px;
    }
    .detail-wrapper{
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .div_box_type_content_url{
    .head_content{
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      color : #101828
    }
    .url_list{
      margin-bottom: 16px;
      a {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        color : #0086C9;
        text-decoration-line: underline;
      }
    }
  }
`

const initRadioList = [
  {
    text: '1',
    value: true,
  },
]
const DivDataExam = styled.div`
  margin-top: 16px;
  .topic_css{
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 32px;
  }
  .ql-container {
    /*min-height: 45px;*/
    font-family: inherit;
  }
  .ql-tooltip{
    display : none;
  }
  .ql-clipboard{
    display : none;
  }
  .ql-editor{
    padding: 0px 0px;
    font-size: 18px;
    h2{
      font-size: 20px;
    }
    p{
      margin : 0px;   
    }
  }
  .exam{
    display: grid;
    grid-template-columns: repeat(2, 8% [ col-start ] 90% [ col-end ]);
    gap: 2%;
    /* ST-TimeLine 03/03/2024 */
    .question_number_font{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color : #344054;
      &.active{
        color : #A91F23;
      }
    }
    ul.timeline_question{
      list-style-type: none;
      position: relative;
    }
    ul.timeline_question:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }
    ul.timeline_question > li {
      margin: 20px 0;
      padding-left: 10px;
    }
    ul.timeline_question > li:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;        
        width: 24px;
        height: 24px;
        background-color: #EAECF0;
        box-shadow: 0px 0px 5px 1px #FFFFFF,inset 0 0 0 1px #EAECF0, inset 0 0 0 7px #FFFFFF, inset 0 0 0 8px #EAECF0;

    }
    ul.timeline_question > li.question_number_active:before {
        cursor: pointer;
        content: '';
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        left: 18px;
        z-index: 400;
        text-align: center;
        width: 24px;
        height: 24px;
        background-color: #A91F23;
        box-shadow: 0px 0px 5px 4px #F4EBFF,inset 0 0 0 1px #A91F23, inset 0 0 0 7px #FEF3F2, inset 0 0 0 8px #A81E23;
    }

    /* ED-TimeLine 03/03/2024 */


    .question_content{

      .question_fix{
        /*padding: 0px 15px;*/
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 16px;
      }
      .question_img{
        margin-top: 16px;
        img {
          width: 450px;
          height: 300px;
        }
      }
      .answer_list{
        margin-top: 48px;
        
        .text_other{
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 16px;
        }
        .box_choice{
          /*
          display: grid;
          grid-template-columns: repeat(2, 49% [ col-start ] 49% [ col-end ]);
          gap: 2%;
          */
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          gap: 10px;
          .answer_box{
            display: grid;
            grid-template-columns: repeat(2, 10% [ col-start ] 70% [ col-end ]);
            gap: 2%;
            border-radius: 8px;
            border: 1px solid #EAECF0;
            background: #FFFFFF;
            padding: 16px;
            width: 48%;
        
            &.correct_answer {
                border: 1px solid #A91F23;
                background: #FEF3F2;
            }
            .radio_css{
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              display: flex;
            }
            .choice_content{
              /*margin-top: 12px;*/
              margin-top: 0px;
            }
            .choice_img{
              margin-top: 16px;
              img {
                width: 330px;
                height: 180px;
              }
            }
          }
        }
      }
    }
  }
  .box_button{
    margin-top: 16px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css_radio_correct_answer{
    display: flex;
    justify-content: end;
  }
`

const ElearningPreview = ({ onClose, onSubmit,elearning_id,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpenData, setisOpenData] = useState(false)
  const [DataInfo, setDataInfo] = useState(null)
  const [QuestionIndex, setQuestionIndex] = useState(0)
  const [DataExam, setDataExam] = useState(null)
  const [tabactive, setTabactive] = useState('Content')
  const [menuTabList, setMenuTabList] = useState([
    {
        name: 'เนื้อหา',
        value: 'Content',
        active_status: true,
    }
  ])
  useEffect(() => {
    const sent_data_to_api = {
        elearning_id : elearning_id,
    };
    dispatch(ApiElearning_profile(sent_data_to_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.data_info){
          setDataInfo(data_info.data_info);
          if(data_info.data_info.exam_data){
            setMenuTabList([
              {
                  name: 'เนื้อหา',
                  value: 'Content',
                  active_status: true,
              },
              {
                  name: 'แบบทดสอบ',
                  value: 'Exam',
                  active_status: false,
              },
            ])
            setDataExam(data_info.data_info.exam_data[0])
            setQuestionIndex(0)
          }
          setisOpenData(true)
        }
      }
    })
  }, [dispatch])



  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  const goto_question = (question_index) => {
    setDataExam(DataInfo?.exam_data[question_index])
    setQuestionIndex(question_index)
  }
  
  //---- Modal Success And Fail
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">
                <DivTab>
                    {menuTabList?.map((item, index) => (
                        <button
                        id={'this_name-' + item.value}
                        key={'btn-' + index}
                        className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                        onClick={() => handleClickTaskMenuTab(item)}
                        >
                            {item.name}
                        </button>
                    ))}
                </DivTab>
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_magin_top_24"></div>
        <div className="border_header"></div>
      </div>
      
      <div className="dialog-content">
        {isOpenData == true && (                
            <div className="content">
                {tabactive == 'Content' && (  
                    <div>
                        <div>
                            <div className="status-badge">
                                {DataInfo.field_study_data && ( 
                                    <div className="status-badge-engineer">
                                        { DataInfo.field_study_data?.map((item, index) => (
                                            <div>
                                                {index > 0 && (   
                                                    <span>,</span>
                                                )}
                                                {item.knowledge_th + '-' + item.field_study_th}
                                                
                                            </div>
                                        ))} 
                                    </div>
                                )}
                                <div>
                                    เวลาอ่าน {DataInfo.time_read_display}
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className="css_magin_top_16"></div>
                        <div className="topic_css">
                            {DataInfo.topic_name}
                        </div>
                        <div className="css_magin_top_24"></div>
                        <div className="detail_css">
                            {DataInfo.detail}
                        </div>
                        {
                          /*
                          {DataInfo.cover_img && ( 
                              <div>
                                  <div className="css_magin_top_24"></div>
                                  <div className="cover_img">
                                      <img src={DataInfo.cover_img } />
                                  </div>
                              </div>
                          )}
                          */
                        }                         


                        <div className="css_magin_top_24"></div>
                        <div className="css_box_date">
                            <div>
                                <div className="topic">วันที่เผยแพร่</div>
                                <div className="detail">
                                    {DataInfo.approve_at ? DateDisplay(DataInfo.approve_at) : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="topic">วันที่อัปเดท</div>
                                <div className="detail">
                                    {DataInfo.updated_at ? DateDisplay(DataInfo.updated_at) : '-'}
                                </div>
                            </div>
                            <div>
                                <div className="topic">เขียนโดย</div>
                                <div className="detail">
                                    {DataInfo.organizations_name}
                                </div>
                            </div>
                        </div>
                        <div>                   
                          <DivDataContent >
                              <div className="content_data">                       
                                {DataInfo?.data_content?.map((item, index) => (
                                  <div>
                                    {item.type_content == 'text' && (
                                      <div>
                                        <div>
                                          <ReactQuill  
                                          value={item.content} 
                                          readOnly={true} 
                                          theme={'bubble'} 
                                          modules={{
                                            clipboard: {
                                              matchVisual: false
                                            }
                                          }}
                                          />
                                        </div>                               
                                        <div className="content_margin_top_14"></div>
                                      </div>                              
                                    )}
                                    {item.type_content == 'img' && (
                                      <div>
                                        <div className="div_box_type_content_img">
                                          <div>
                                              <div className="div_box_type_content_img_head_content">
                                                  {item.head_content}
                                              </div>
                                              {item.FileInfoList?.length > 0 && ( 
                                                  <div className="div_box_type_content_img_url">
                                                      <img src={item.FileInfoList[0].url } />
                                                  </div>
                                              )}
                                              <div className="div_box_type_content_img_sub_content">
                                                  {item.sub_content}
                                              </div>
                                          </div>
                                        </div>
                                        <div className="content_margin_top_14"></div>
                                      </div>
                                    )}                            
                                    {item.type_content == 'vdo' && (
                                      <div>
                                        <div className="div_box_type_content_vdo">
                                          {item.content && (
                                            <div>
                                                <div>
                                                  <ReactPlayer
                                                      url={item.content}
                                                      className='react-player'
                                                      playing={false}
                                                      width='720px'
                                                      height='440px'
                                                  />
                                                </div>
                                                <div className="div_box_type_content_vdo_sub_content">
                                                  {item.sub_content}
                                                </div>

                                            </div>
                                          )}
                                        </div>
                                        <div className="content_margin_top_14"></div>
                                      </div>
                                    )}
                                    {item.type_content == 'pdf' && (
                                      <div>
                                        {item.FileInfoList?.length > 0 && ( 
                                          <div className="div_box_type_content_pdf">
                                            <div className="icon-wrapper">
                                              <img src={iconFile} />
                                            </div>
                                            <div className="detail-wrapper">
                                                <div className="file-name">
                                                  <a href={item.FileInfoList[0].url} target="_blank" rel="noreferrer">
                                                    {item.FileInfoList[0].name}
                                                  </a>
                                                </div>
                                                <div className="file-size">{item.FileInfoList[0].size} KB</div>
                                            </div>
                                          </div>
                                        )}

                                        <div className="content_margin_top_14"></div>
                                      </div>
                                    )}
                                    {item.type_content == 'url' && (
                                      <div>
                                        <div className="div_box_type_content_url">
                                          <div className="head_content">
                                            {item.head_content}
                                          </div>
                                          <div className="content_margin_top_14"></div>
                                          {item?.url_list?.map((item_sub, index_sub) => (
                                            <div className="url_list">
                                                  <a href={item_sub.link_url} target="_blank" rel="noreferrer">
                                                    {item_sub.link_name}
                                                  </a>
                                            </div>                  
                                          ))}
                                        </div>
                                        <div className="content_margin_top_14"></div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                          </DivDataContent>
                        </div>
                    </div>

                )}
                {tabactive == 'Exam' && (  
                    <DivDataExam >
                        <div className="topic_css">
                            {DataInfo.topic_name}
                        </div>
                        <div className="exam">
                            {
                              /*
                              <div className="question_number">
                                {DataInfo?.exam_data?.map((item, index) => (
                                  <div className="section">
                                    <div className="line_1"></div>
                                    <div className={`user_img ${index == QuestionIndex ? 'active' : ''}`} >
                                      {index + 1}
                                    </div>
                                    
                                  </div>
                                ))}  
                              </div>
                              */
                            }
                             <div >
                              <ul className="timeline_question">
                                {DataInfo?.exam_data?.map((item, index) => (
                                  <li className={`${index == QuestionIndex ? 'question_number_active' : ''}`} onClick={() => goto_question(index)}>
                                    <div className={`question_number_font ${index == QuestionIndex ? 'active' : ''}`}>{index + 1}.</div>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <div className="question_content">
                                <div className="question_fix">คำถาม</div>
                                <div >
                                <ReactQuill  
                                  value={DataExam?.question} 
                                  readOnly={true} 
                                  theme={'bubble'} 
                                  modules={{
                                    clipboard: {
                                      matchVisual: false
                                    }
                                  }}
                                />
                                </div>  
                                {DataExam?.question_img && (
                                  <div className="question_img">
                                      <img src={DataExam?.question_img } />
                                  </div>
                                )}
                                <div className="answer_list">
                                  <div className="text_other">
                                    ตัวเลือก พร้อมเฉลย
                                  </div>
                                  <div className="box_choice">
                                    {JSON.parse(DataExam?.answer_list)?.map((item_choice, index_choice) => (
                                      <div className={`answer_box ${item_choice.is_answer ? 'correct_answer' : ''}`}>
                                        <div className="radio_css">
                                          {
                                            /*
                                                                                      <div>
                                            {'ตัวเลือกที่ ' + (index_choice + 1)}
                                          </div>
                                          <div>
                                            <RadioButton 
                                              optionList={[{value: true,}]} 
                                              value={item_choice.is_answer} 
                                            />  
                                          </div>
                                            */
                                          }


                                          <Field className="field" label={'ตัวเลือกที่ ' + (index_choice + 1)}>
                                              <div className="css_radio_correct_answer">
                                                <RadioButton 
                                                  optionList={[{value: true,}]} 
                                                  value={item_choice.is_answer} 
                                                />
                                              </div>                                       
                                          </Field>
                                        </div>
                                        <div className="choice_content">
                                          <div >
                                                                                    
                                            <div>
                                              <ReactQuill  
                                                value={item_choice.choice} 
                                                readOnly={true} 
                                                theme={'bubble'} 
                                                modules={{
                                                  clipboard: {
                                                    matchVisual: false
                                                  }
                                                }}
                                              />
                                            </div>
                                            
                                          </div>
                                          {item_choice.choice_image && (
                                              <div className="choice_img">
                                                  <img src={item_choice.choice_image } />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="box_button">
                                  {
                                    QuestionIndex > 0 ? (
                                      <ButtonSecondary append={<img src={iconBack} />} onClick={() => goto_question(QuestionIndex - 1)}>
                                        ย้อนกลับ
                                      </ButtonSecondary>
                                    ) : <div></div>
                                  }  
                                  {
                                    QuestionIndex < (DataInfo?.exam_data.length - 1) ? (
                                      <Button prepend={<img src={iconNext} />} onClick={() => goto_question(QuestionIndex + 1)}>
                                        ข้อถัดไป
                                      </Button>
                                    ) : <div></div>
                                  }
                                </div>
                            </div>
                        </div>
                    </DivDataExam>
                )}
            </div>
        )}
      </div>
      

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes='ตกลง'
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => {
          setFailModal(false)
        }}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes='ตกลง'
      />
    </Div>
  )
}

export default ElearningPreview
