import { getAPI, postAPI,patchAPI } from '../../utils/api'
import { 
    GET_APPEAL,
    POST_APPEAL,
    UPDATE_APPEAL
} from '../actionTypes'
import { showLoading, hideLoading } from './loading'

export const ApiGetVWMemberEventPoint = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
    try {
      
      dispatch(showLoading())
      dispatch({ type: GET_APPEAL.REQUEST })
  
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
      let params = {
        '$limit': filter.limit,
        '$skip': filter.skip,
      }
  
      if(filter.event_code){
        params = {...params,'event_code': filter.event_code}
      }

      if(filter.ref_activity){
        params = {...params,'ref_activity': filter.ref_activity}
      }
  
      const data_result = await getAPI({
        url: '/api/events/vw_member_user_point_history?$sort[id]=-1',
        headers: headers,
        params,
      })
  
      return { type: 'ApiGetVWMemberEventPoint_SUCCESS', data_info: data_result }
  
    } catch (err) {
      return { type: 'ApiGetVWMemberEventPoint_ERROR' }
  
    } finally {
      dispatch(hideLoading())
    }
}

export const ApiMemberAppeal = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_APPEAL.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/appeal/MemberAppeal`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiMemberAppeal_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiMemberAppeal_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiGetVWAppeal = (filter = { limit: 10, skip: 0 }) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_APPEAL.REQUEST })

    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    let params = {
      '$limit': filter.limit,
      '$skip': filter.skip,
    }

    const data_result = await getAPI({
      url: '/api/appeal/Member_vw_appeal?$sort[id]=-1',
      headers: headers,
      params,
    })

    return { type: 'ApiGetVWAppeal_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiGetVWAppeal_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}

export const ApiAppealInfo = (data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: POST_APPEAL.REQUEST })
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    }
    
    const data_result = await  postAPI({
      url: `/api/appeal/MemberAppealInfo`,
      data,
      headers: headers,
    })
    
    return { type: 'ApiAppealInfo_SUCCESS', data_info: data_result }

  } catch (err) {
    return { type: 'ApiAppealInfo_ERROR' }

  } finally {
    dispatch(hideLoading())
  }
}