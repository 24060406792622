import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate,useParams } from 'react-router-dom'

import {  getAdminUser,DateDisplay,DateTimeHHmmDisplay,TimeDisplayHHmm,DateDisplayFullNameMonth } from '../../utils/common'
import { ApiGetEventInfo,ApiMemberPreregister } from '../../redux/actions/events'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import Field from '../../components/form/Field'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { LuTable2 } from "react-icons/lu";
import { CiCalendarDate } from "react-icons/ci";
import { PiClockClockwise } from "react-icons/pi";
import iconFile from '../../assets/images/pdf-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'


const Div = styled.div`
   margin-bottom: 50px;
   padding: 10px;
   .cursor_pointer{
    cursor: pointer;
   }
  .box_header{
    display: flex;
    justify-content: space-between;
  }
  .content-title {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .content-title-text {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .css_button_add{
    display: inline-block;
    align-self: flex-end;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .css_magin_top_10 {
    margin-top: 10px;
  }
  .css_magin_top_20 {
    margin-top: 20px;
  }
  .css_magin_top_24 {
    margin-top: 24px;
  }
  .button_CreateModal {
    color: #ffffff;
    background-color: #17b26a;
    border: 1px solid #17b26a;
  }
  .box_page {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
  }
  .group_status{
    display: flex;
    gap: 10px;
  }
  .box_engineer{
    .status-badge {
      width: fit-content;
      padding: 2px 6px;
      text-align: center;
      font-weight: 500;
      border-radius: 16px;
      color: #B54708;
      background: #FFFAEB;
      border: 1px #FEDF89 solid;
      .status {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        color: #B54708;    
      }
    }
  }
  .box_event_status{
    .status-badge {
      width: fit-content;
      padding: 2px 6px;
      text-align: center;
      font-weight: 500;
      border-radius: 16px;
      color: #344054;
      background: #f5f5f4;
      border: 1px #e7e5e4 solid;
      .status {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        color: #475467;    
      }
    }
  }
  .group_content{
    display: grid;
    grid-template-columns: repeat(2, 30% [ col-start ] 68% [ col-end ]);
    gap: 2%;
  }
`
const DivContentLeft = styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;    
    .main_box{
        padding : 24px;
        background: #F9FAFB;
        border-radius: 12px;
    }
    .css_magin_top_10 {
        margin-top: 10px;
    }
    .css_magin_top_20 {
        margin-top: 20px;
    }
    .section{
        color : #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }
    .section_date_activity{
        color : #475467;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        .box_date{
            display: flex;
            /*align-items: center;*/
            gap: 12px;
            margin: 5px 0px;
            .text_css{
                color : #A91F23;
            }
        }
    }
`

const DivContentRight = styled.div`
    /*padding : 24px;*/
    background: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color : #475467;
    white-space: pre-wrap;
    line-break: anywhere;
    .css_magin_top_10 {
        margin-top: 10px;
    }
    .css_magin_top_20 {
        margin-top: 20px;
    }
    .css_magin_top_24 {
        margin-top: 24px;
    }
    .section{
        color : #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
    }
    .box_preregister{
        background: #F9FAFB;
        padding : 32px;
        border-radius: 12px;
        .flex_space{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color : #475467;
            .text_title{
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                color : #101828;
            }
            .text_org{
                color : #A91F23;
            }
        }
    }
    .upload-list {
        width: 65%;
        .file {
          width: 100%;
          display: inline-flex;
          padding: 14px;
          margin-top: 16px;
          background: var(--Base-White);
          border: 1px solid var(--Gray-300);
          box-shadow: var(--Shadow-xs);
          border-radius: 8px;
        }
    
        .icon-wrapper {
          width: 32px;
        }
    
        .detail-wrapper {
          flex: 1;
          margin: 0 14px;
          font-size: 14px;
          overflow: hidden;
    
          .file-name {
            margin-bottom: 2px;
            text-overflow: ellipsis;
            overflow: hidden;
    
            a {
              font-weight: 500;
              color: var(--Gray-700);
            }
          }
        }
      }
      .box_money{
        display: flex;
        align-items: center;
        gap: 40px;
      }
      .box_contact{
        display: flex;
        align-items: center;
        gap: 32px;
        .text_name{
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            color : #344054;
        }
        .text_red{
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            color : #A91F23;
        }
      }
`

const DivWarning = styled.div`
    color: #475467;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.75rem;
    border: 1px solid #475467;
    background: #FFFFFF;
    padding: 1rem;
    .d-flex {
        display: flex;
        align-items: center;
    }
    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
      }
      .detail_click{
        color : #A91F23;
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin-top : 12px;
      }
    }
    .box_icon {
        border-radius: 50%;
        background-color: #FEF0C7;
        width: 48px;
        height: 48px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 12px;
        color: #A91F23;
        cursor: pointer;
      }
`


const EventsInfo = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const initMessageModal = {
        headline: '',
        subtitle: '',
        description: '',
    }
    const [successModal, setSuccessModal] = useState(false)
    const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
    const [failModal, setFailModal] = useState(false)
    const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

    const [isOpenData, setisOpenData] = useState(false)
    const [DataInfo, setDataInfo] = useState(null)

    useEffect(() => {
        const sent_data_to_api = {
            event_id : id,
        };
        dispatch(ApiGetEventInfo(sent_data_to_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.data_info){
            setDataInfo(data_info.data_info);
            setisOpenData(true)
            }
        }
        })

    }, [dispatch])

    const backToHome = () => {
        navigate('/Activity/Events')
    }
    const ApiProcessPreregister = () => {
        const data_to_api = {
            event_id : id
          };
          
          dispatch(ApiMemberPreregister(data_to_api)).then(({ type,data_info }) => {
            if(type.endsWith('_SUCCESS')){
              if(data_info.code == 200){
                setSuccessMessageModal({
                    headline: 'ลงทะเบียนสำเร็จ',
                    subtitle: data_info.full_name,
                    description: 'ขอบคุณที่สนใจเข้าร่วมกิจกรรม',
                })
                setSuccessModal(true);
              }else{
                setFailMessageModal({
                  headline: 'ขออภัยไม่สามารถลงทะเบียนได้',
                  description: data_info.message,
                })
                setFailModal(true)
              }
            }else{
              setFailMessageModal({
                headline: 'internal server error',
                description: '',
              })
              setFailModal(true)
            }
        })
    }
  return (
    <Div>
        {isOpenData == true && ( 
            <div>
                <div className="box_page">
                    <LuTable2 size={20} className="mr-8-px" />
                    <MdOutlineKeyboardArrowRight className="mr-8-px" />
                    <span className="cursor_pointer mr-8-px" onClick={backToHome}>
                        ตารางกิจกรรมองค์กรแม่ข่าย
                    </span>
                    <MdOutlineKeyboardArrowRight className="mr-8-px" />
                    <span className="highlight">{DataInfo.name.length > 50 ? DataInfo.name.substring(0,50)+ '...' : DataInfo.name}</span>
                </div>
                <div className="css_magin_top_20"></div>
                <div className="box_header">
                    <div>
                        <div className="content-title">
                            {DataInfo.name}
                        </div>
                        <div className="content-title-text">
                            รหัสกิจกรรม : {DataInfo.code}
                        </div>
                    </div>            
                </div>
                <div className="css_magin_top_20"></div>
                <div className="border_header"></div>
                <div className="css_magin_top_20"></div>
                <div className="group_status">
                    <div className="box_engineer">
                        { DataInfo.field_study_data && (
                            <div className="status-badge">
                                { DataInfo.field_study_data?.map((item, index) => (
                                    <span>
                                        {index > 0 && (   
                                            <span>,</span>
                                        )}
                                        {item.knowledge_th + '-' + item.field_study_th}
                                        
                                    </span>
                                ))} 
                            </div>
                        )}
                        {
                            /*
                                <div className="status-badge">
                                    {DataInfo.engineering_field_name}
                                </div>
                            */
                        }

                    </div>
                    <div className="box_event_status">
                        <div
                        className="status-badge"
                        style={{
                            borderColor: DataInfo.status_badge_color?.border,
                            backgroundColor: DataInfo.status_badge_color?.background,
                        }}
                        >
                        <GrStatusGoodSmall 
                            className="status"
                            style={{
                            color: DataInfo.status_badge_color?.color,
                            }}
                        />
                            {DataInfo.status_name}
                        </div>
                    </div>
                </div>
                <div className="css_magin_top_20"></div>
                <div className="group_content">
                    <DivContentLeft>
                        <div className="main_box">                        
                            <div className="section">
                                กำหนดการกิจกรรม
                            </div>
                            <div className="css_magin_top_10"></div>
                            { (!DataInfo.event_date_list && !DataInfo.date_event_oneday) && (     
                                <div className="section_date_activity">
                                    <div className="box_date">
                                        <div>
                                            <CiCalendarDate size={22} />
                                        </div>
                                        <div className="text_css">
                                            {DateTimeHHmmDisplay(DataInfo.start_at)} - {DateTimeHHmmDisplay(DataInfo.end_at)}
                                        </div>                                
                                    </div>
                                </div>
                            )}
                            { (!DataInfo.event_date_list && DataInfo.date_event_oneday) && (     
                                <div className="section_date_activity">
                                    <div className="box_date">
                                        <div>
                                            <CiCalendarDate size={22} />
                                        </div>
                                        <div className="text_css">
                                            {DateDisplayFullNameMonth(DataInfo.start_at)}
                                        </div> 
                                        <div>
                                            <PiClockClockwise size={22} />
                                        </div>
                                        <div className="text_css">
                                            เวลา {TimeDisplayHHmm(DataInfo.start_at)} - {TimeDisplayHHmm(DataInfo.end_at)} น.
                                        </div>
                                    </div>
                                </div>
                            )}
                            { (DataInfo.event_date_list) && ( 
                                <div className="section_date_activity">
                                    {DataInfo?.event_date_list?.map((item, index) => (
                                        <div className="box_date">
                                            <div>
                                                <CiCalendarDate size={22} />
                                            </div>
                                            <div className="text_css">
                                                {DateDisplayFullNameMonth(item.date_event)}
                                            </div> 
                                            <div>
                                                <PiClockClockwise size={22} />
                                            </div>
                                            <div className="text_css">
                                                เวลา {item.timestart_event} - {item.timeend_event} น.
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                วันที่เปิด - รับลงทะเบียน
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DateDisplayFullNameMonth(DataInfo.registration_open_at)} - {DateDisplayFullNameMonth(DataInfo.registration_close_at)}
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                สถานที่จัดกิจกรรม
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.location_type ? DataInfo.location_type : ''}
                            </div>
                            <div>
                                {DataInfo.address_extra_info ? DataInfo.address_extra_info : ''}
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                Link เข้าประชุมออนไลน์
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.conference_link ? DataInfo.conference_link : '-'}
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                จำนวนชั่วโมง
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.duration_in_hours ? DataInfo.duration_in_hours : '-'} ชั่วโมง
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                คะแนน CPD ที่คาดว่าจะได้รับ
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.point ? DataInfo.point : '-'} คะแนน
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                จัดโดย
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.organizations_name ? DataInfo.organizations_name : '-'}
                            </div>
                        </div>
                    </DivContentLeft>
                    <DivContentRight>
                        { (DataInfo.is_open_preregister == true) && (                 
                            <div>
                                <div className="box_preregister">
                                    <div className="flex_space">
                                        <div>
                                            <div className="text_title">ลงทะเบียนฟรี รับคะแนน CPD ทันทีเมื่อจบกิจกรรม</div>
                                            <div>จัดโดย <span className="text_org">{DataInfo.organizations_name}</span></div>
                                        </div>
                                        <div>
                                            <Button  onClick={ApiProcessPreregister}>
                                                ลงทะเบียน Pre-registration
                                            </Button>
                                        </div>
                                    </div>        
                                </div>
                                <div className="css_magin_top_20"></div>
                            </div>
                        )} 

 
                        <div className={`${DataInfo.is_open_preregister == false ? 'css_magin_top_24' : ''}`}>
                            <div className="section">
                                รายละเอียดกิจกรรม
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div>
                                {DataInfo.description ? DataInfo.description : '-'}
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                รายชื่อวิทยากร
                            </div>
                            <div className="css_magin_top_10"></div>
                            {DataInfo?.instructors?.map((item, index) => (
                                <div>
                                    {index + 1}. {item.full_name}
                                </div>
                            ))}
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                เอกสารประกอบกิจกรรม
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div className="upload-list">
                                {DataInfo?.list_file?.map((item, index) => (
                                    <div className="file">
                                        <div className="icon-wrapper">
                                            <img src={iconFile} />
                                        </div>
                                        <div className="detail-wrapper">
                                            <div className="file-name">
                                                <a href={item.file_url} target="_blank" rel="noreferrer">
                                                    {item.file_name}
                                                </a>
                                            </div>
                                            { (item.file_size) && ( 
                                                <div className="file-size">{item.file_size} KB</div>  
                                            )}                                      
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="css_magin_top_20"></div>
                            <div className="section">
                                ค่าธรรมเนียม
                            </div>
                            <div className="css_magin_top_10"></div>
                            <div className="box_money">
                                <div>
                                    <div>ค่าธรรมเนียมสำหรับสมาชิกองค์กรแม่ข่าย</div>      
                                    <div>ค่าธรรมเนียมสำหรับบุคคลทั่วไป</div> 
                                    <div>ค่าธรรมเนียมสำหรับนักเรียน/นักศึกษา</div>            
                                </div>
                                <div>
                                    <div>{DataInfo?.coe_member_attending_fee ? DataInfo?.coe_member_attending_fee : 0} บาท</div> 
                                    <div>{DataInfo?.individual_attending_fee ? DataInfo?.individual_attending_fee : 0} บาท</div>   
                                    <div>{DataInfo?.student_attending_fee ? DataInfo?.student_attending_fee : 0} บาท</div>                
                                </div>                                
                            </div>
                            
                            { (DataInfo.type_org == 0) && ( //---- สำหรับองค์กรภายนอก 
                                <div>
                                    <div className="css_magin_top_20"></div>
                                    <DivWarning>
                                        <div className="d-flex">
                                            <div className="icon-warning-container">
                                                <div className="box_icon" >
                                                    <PiWarningCircleBold size={25} />
                                                </div>                    
                                            </div>
                                            <div className="warning-message-container">
                                                <div className="headline-warning">
                                                    การลงทะเบียนเข้าร่วมกิจกรรม กรุณาติดต่อลงทะเบียนที่องค์กรแม่ข่าย
                                                </div>
                                            </div>
                                        </div>                                    
                                    </DivWarning>                  
                                </div>
                            )} 
                            <div className="css_magin_top_20"></div>
                            <div className="box_contact">
                                <div>
                                    <div>สนใจติดต่อ</div>   
                                    <div className="text_name">{DataInfo?.contact_person_name ? DataInfo?.contact_person_name : '-'}</div>   
                                </div> 
                                <div>
                                    <div>เบอร์โทรศัพท์</div>   
                                    <div className="text_red">{DataInfo?.contact_person_phone_no ? DataInfo?.contact_person_phone_no : '-'}</div>  
                                </div>  
                                <div>
                                    <div>Email</div>   
                                    <div className="text_red">{DataInfo?.contact_person_email ? DataInfo?.contact_person_email : '-'}</div>  
                                </div>  
                                <div>
                                    <div>Website กิจกรรม</div>   
                                    <div className="text_red">
                                        {DataInfo?.organization_link ? DataInfo?.organization_link : '-'}
                                    </div>  
                                </div>                 
                            </div>
                        </div>
                    </DivContentRight>
                </div>
            </div>
        )}
        <DialogSuccess
            open={Boolean(successModal)}
            onClose={() => setSuccessModal(false)}
            onSubmit={backToHome}
            icon={successIcon}
            title={successMessageModal.headline}
            subtitle={successMessageModal.subtitle}
            description={successMessageModal.description}
            textYes={'ตกลง'}
        />
       <DialogFail
            open={Boolean(failModal)}
            onClose={() => setFailModal(false)}
            onSubmit={() => {
                setFailModal(false)
            }}
            icon={failIcon}
            title={failMessageModal.headline}
            description={failMessageModal.description}
            textYes='ตกลง'
        />
    </Div>
  )
}

export default EventsInfo
