import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import XLSX from "xlsx";

import {  getAdminUser,DateDisplayDDMMBBB,DateTimeHHmmDisplay,DisplayTableActivityName } from '../../utils/common'
import { ApiGetVWUserHistoryPoint } from '../../redux/actions/member'

import Button from '../../components/form/button/Button'
import ButtonSecondary from '../../components/form/button/ButtonSecondary'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import Field from '../../components/form/Field'
import SidePanel from '../../components/common/SidePanel'
import ViewElearning from '../../pages/member/ViewElearning'
import ViewEvent from '../../pages/member/ViewEvent'
import ViewPointLog from '../../pages/member/ViewPointLog'
import Modal from '../../components/common/Modal'

import iconPlus from '../../assets/images/icon-plus.svg'
import { FaBullseye } from 'react-icons/fa'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import iconFileDowload from '../../assets/images/icon-file-download.svg'

const Div = styled.div`
   margin-bottom: 50px;
   padding: 10px;
  .box_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Base-Font-Title-Color);
  }
  .cursor_pointer {
    cursor: pointer;
  }

  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
  }
  .css_magin_top_32 {
    margin-top: 32px;
  }
  .box_page {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
  }
  .mr-8-px {
    margin-right: 8px;
  }
  .box_dowload{
    display: flex;
    justify-content: space-between;
  }
  .title_dowload{
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .status-badge {
    width: fit-content;
    padding: 2px 6px;
    text-align: center;
    font-weight: 500;
    border-radius: 16px;
    color: #475467;
    background: #f5f5f4;
    border: 1px #e7e5e4 solid;
}

.status-badge-2 {
  width: fit-content;
  padding: 2px 6px;
  text-align: center;
  font-weight: 500;
  border-radius: 16px;
  color: #475467;
  background: #f5f5f4;
  border: 1px #e7e5e4 solid;

  .status {
    font-size: 0.6rem;
    margin-right: 0.2rem;
    color: #475467;
  }
}

.button_view_data {
  color: #A91F23;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
}
`

const DivFilter = styled.div`
min-height: 68px;
background-color: var(--Gray-50);
border-radius: 8px;
margin-bottom: 21px;
padding: 10px;
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.css_filter_name {
    min-width: 380px;
    margin-right: 10px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.css_button_filter{
    display: inline-block;
    align-self: flex-end;
}
.dd_filter{
    min-width: 380px;
}
.dd_filterStatusExam{
  min-width: 115px;
}

`

const DivTable = styled.div`
border: 1px solid #eaecf0;
box-shadow: var(--Shadow-xs);
border-radius: 8px;
background-color: #ffffff;

.table-header {
  display: flex;
  justify-content: space-between;
  padding: 16px 14px;
  border-radius: 8px 8px 0 0;
  background: var(--Table-Bg-Header);

  .group {
    display: flex;
    align-items: center;
  }

  .table-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .table-total {
    margin-left: 16px;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    color: var(--BADGE-Table-Font);
    background: var(--BADGE-Table-Bg);
    border-radius: 16px;
    border: 1px var(--BADGE-Table-Border) solid;
  }
}

.create-bt {
  text-decoration: none;
}
.css_magin_top_10 {
  margin-top: 10px;
}
`



const CpdPointHistory = () => {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const navigate = useNavigate()

  const [DataInfo, setDataInfo] = useState(null)
  const [RefActivityOption, setRefActivityOption] = useState([
    {
        text: 'E-learning',
        value: 'E-learning',
    },
    {
        text: 'กิจกรรมแม่ข่าย',
        value: 'กิจกรรมแม่ข่าย',
    },
    {
        text: 'Self-declaration',
        value: 'Self-declaration',
    },
  ])
  const [Filter, setFilter] = useState({
    activity_name : null,
    ref_activity : '',
    effective_date : null
  })

  const limit_data = 10;
  const [total, settotal] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumnData = [
    {
        Header: 'ลำดับ',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'ที่มา',
        accessor: 'ref_activity',
        disableSortBy: false,
    },
    {
        Header: 'รายละเอียด / ชื่อกิจกรรม',
        accessor: 'activity_name',
        disableSortBy: false,
    },
    {
        Header: 'คะแนนที่ได้รับ',
        accessor: 'user_point',
        disableSortBy: false,
    },
    {
        Header: 'วันที่ได้คะแนน',
        accessor: 'effective_date',
        disableSortBy: false,
    },
    {
        Header: 'วันที่คะแนนหมดอายุ',
        accessor: 'expire_date',
        disableSortBy: false,
    },
    {
      Header: 'สถานะ',
      accessor: 'status_name',
      disableSortBy: false,
    },
    {
        accessor: 'view_activity',
        disableSortBy: false,
    },
    {
      accessor: 'activity_point_log',
      disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {

      dispatch(ApiGetVWUserHistoryPoint(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if (data_info.total > 0) {
            const totalPage = Math.ceil(data_info.total / limit_data)
            settotal(data_info.total)
            setTotalPages(totalPage)

            const newTableCellData = data_info.data.map((v, index) => ({
              no: limit_data * page - (9 - index),
              
              ref_activity: (
                v.ref_activity ? 
                <div
                  className="status-badge"
                  style={{
                    color: v.status_badge_color?.color,
                    borderColor: v.status_badge_color?.border,
                    backgroundColor: v.status_badge_color?.background,
                  }}
                >
                  {v.ref_activity}
                </div>
                : 
                <div >-</div>
              ),
              activity_name: v.activity_name ? DisplayTableActivityName(v.activity_name) : '-',
              user_point: v.user_point ? v.user_point : '-',
              effective_date: v.effective_date ? DateDisplayDDMMBBB(v.effective_date) : '-',
              expire_date: v.expire_date ? DateDisplayDDMMBBB(v.expire_date) : '-',
              status_name: (
                v.status_name ? 
                <div
                  className="status-badge-2"
                  style={{
                    borderColor: v.status_name_badge_color?.border,
                    backgroundColor: v.status_name_badge_color?.background,
                  }}
                >
                  <GrStatusGoodSmall 
                    className="status"
                    style={{
                      color: v.status_name_badge_color?.color,
                    }}
                  />
                  {v.status_name}
                </div>
                : 
                <div >-</div>
              ),
              view_activity: (
              <ButtonSecondary  onClick={() => OpenViewModal(v)}>
                ดูข้อมูล
              </ButtonSecondary>
              ),
              activity_point_log: (
                <ButtonSecondary className="button_view_data"  onClick={() => OpenPointLog(v)}>
                  ดูประวัติ
                </ButtonSecondary>
              ) 
            }))
            setTableCellData(newTableCellData)
          }else{
            settotal(0);
            setPage(0);
            setTotalPages(0);
            setTableCellData([]);
          }
         
        }
      })

    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter) //-- ปิด รอ API
  }, [fetchData, limit_data])


  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: (value.page  - 1) * limit_data, 
      limit: limit_data,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onSearch = () => {
    const filter = {
      skip: 0,
      limit: limit_data,
      activity_name : Filter.activity_name,
      ref_activity : Filter.ref_activity,
      effective_date : Filter.effective_date,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearSearch = () => {
    setFilter({
        activity_name : '',
        ref_activity : '',
        effective_date : null
    })
    const filter = {
      skip: 0,
      limit: limit_data,
    }
    fetchData(filter)
    setPage(1)
  }

  const onClearfilterName = () => {
    setFilter({ ...Filter, activity_name: '' })
  }

  const [ThisId, setThisId] = useState(false)
  const [WidthSidePanel, setWidthSidePanel] = useState(400);
  const [ModalViewElearning, setModalViewElearning] = useState(false);
  const [ModalViewEvent, setModalViewEvent] = useState(false);

  const OpenViewModal = (Obj) => {
    setThisId(Obj.ref_activity_id);
    if(Obj.ref_activity == 'E-learning'){
      setModalViewElearning(true);
    }else{
      setModalViewEvent(true);
    }
    
  }

  const [ObjPoint, setObjPoint] = useState({});
  const [ModalPointLog, setModalPointLog] = useState(false);
  const OpenPointLog = (Obj) => {
    //alert(JSON.stringify(Obj))
    setObjPoint(Obj);
    setModalPointLog(true);
  }

  

  const backToHome = () => {
    navigate('/Member/CpdPoint')
  }
  const DowloadReport = () => {
    const filter = {
      skip: 0,
      limit: 1000,
    }
    dispatch(ApiGetVWUserHistoryPoint(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_info.total > 0){
                const resultToxlsx = data_info.data.map((v,index) => {
                    return {
                        "ลำดับ" : index + 1,
                        "ที่มา" : v.ref_activity  ? v.ref_activity : '-',
                        "รายละเอียด / ชื่อกิจกรรม" : v.activity_name  ? v.activity_name : '-',
                        "คะแนนที่ได้รับ" : v.user_point ? v.user_point : '-',
                        "วันที่ได้คะแนน" : v.effective_date ? DateDisplayDDMMBBB(v.effective_date) : '-',
                        "วันที่คะแนนหมดอายุ" : v.expire_date ? DateDisplayDDMMBBB(v.expire_date) : '-',
                        "สถานะ" : v.status_name ? v.status_name : '-',
                    };
                });
                const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, dataWS);
                XLSX.writeFile(wb, "MyCPD.xlsx");
            }
        }
    })
  }
  return (
    <Div>
        <div className="box_header">
            <div>
                <div className="content-title">
                    My CPD
                </div>
                <div className="content-title-text">
                    ตรวจสอบคะแนนและคำนวนการนำคะแนนไปใช้
                </div>
            </div>
        </div>
        <div className="css_magin_top_32"></div>
        <div className="border_header"></div>
        <div className="css_magin_top_32"></div>
        <div className="box_page">
            <span className="cursor_pointer mr-8-px" onClick={backToHome}>
            หน้าแรก
            </span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="highlight">ประวัติการได้คะแนน</span>
        </div>
        <div className="css_magin_top_32"></div>
        <div className="box_dowload">
            <div className="title_dowload">
                ประวัติการได้คะแนน
            </div>
            <div >
              <ButtonSecondary append={<img src={iconFileDowload} />} onClick={DowloadReport} >
                ดาวน์โหลด
              </ButtonSecondary>
            </div>
        </div>
        <div className="css_magin_top_32"></div>
        <DivFilter>
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_filter_name">
                <Field className="field" label="ค้นหา">
                  <SearchText
                    placeholder="ค้นหารายละเอียด / ชื่อกิจกรรม"
                    value={Filter.activity_name}
                    onChange={(e) => setFilter({ ...Filter, activity_name: e.target.value })}
                    onClear={onClearfilterName}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="ประเภทที่มาของคะแนน">
                  <Dropdown
                      id={`dd_filter`}
                      className="dd_filter"
                      value={Filter.ref_activity}
                      optionList={RefActivityOption}
                      onChange={(v) => setFilter({ ...Filter, ref_activity: v })}
                      placeHolder={'เลือก'}
                  />
                </Field>
              </div>
              <div className="mr-1-rem">
                <Field className="field" label="วันที่ได้คะแนน">
                    <Date
                        className={'input-calendar'}
                        value={Filter.effective_date}
                        onChange={(v) => setFilter({ ...Filter, effective_date: v })}
                    />
                </Field>
              </div>
              <div className="css_button_filter mr-1-rem">
                  <Button onClick={onSearch} >
                    ค้นหา
                  </Button>
              </div>
              <div className="css_button_filter mr-1-rem">
                <ButtonSecondary onClick={onClearSearch} >
                  ล้างการค้นหา
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </DivFilter>
        <div className="css_magin_top_32"></div>
        <DivTable>
            <div className="table-header">
                <div className="group">
                  <div className="table-title">จำนวนรายการ</div>
                  <div className="table-total">
                      {total} รายการ
                  </div>
                </div>
            </div>
            <div className="css_magin_top_10">
              <TableV2
                columns={TableColumnData}
                data={TableCellData}
                onStateChange={onChangePaginationAttendeeAttend}
                pageCount={totalPages}
                page={page}
                loading={false}
              />
            </div>
        </DivTable>

        <SidePanel isOpen={ModalViewElearning} setIsOpen={setModalViewElearning} width={WidthSidePanel}>
            <ViewElearning
              width={WidthSidePanel} 
              onClose={() => setModalViewElearning(false)} 
              onSubmit={() => {
                setModalViewElearning(false)
              }}
              ThisId={ThisId}
            />
        </SidePanel>
        <SidePanel isOpen={ModalViewEvent} setIsOpen={setModalViewEvent} width={WidthSidePanel}>
            <ViewEvent
              width={WidthSidePanel} 
              onClose={() => setModalViewEvent(false)} 
              onSubmit={() => {
                setModalViewEvent(false)
              }}
              ThisId={ThisId}
            />
        </SidePanel>
        <Modal open={ModalPointLog} disableBackdropClick>
            <ViewPointLog
              onClose={() => setModalPointLog(false)} 
              onSubmit={() => {
                setModalPointLog(false)
              }}
              ObjPoint={ObjPoint}
            />
        </Modal>
    </Div>
  )
}

export default CpdPointHistory
