import styled from 'styled-components'
import iconClose from '../../assets/images/icon-close.svg'
import IconButton from '../common/IconButton'
import Modal from '../common/Modal'
import Button from '../form/button/Button'
import ButtonSecondary from '../form/button/ButtonSecondary'

import PropTypes from 'prop-types'

const Div = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  max-width: 400px;

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
      margin-right: 16px;
      margin-bottom: 8px;
    }

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
      /*text-align: center;*/
      margin-bottom: 1rem;
    }

    .name-item {
      color: var(--gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      /*text-align: center;*/
      /*margin-bottom: 1rem;*/
    }

    .description {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      /*text-align: center;*/
      /*margin-bottom: 1rem;*/
    }
    .margin_bottom {
      margin-bottom: 1rem;
    }
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .close-modal-content {
    display: flex;
    justify-content: flex-end;
    /*text-align: center;*/
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .bt-close {
    img {
      width: 16px;
      height: 16px;
    }
  }
  .top-table{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    /*margin-bottom: 24px;*/
  }
  .d-flex-consent {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
`

const DialogConfirmV2 = ({
  open,
  onClose,
  nameItem,
  icon,
  title,
  description,
  textYes,
  textNo,
  onSubmit,
  onNo,
  subTitle,
  html,
  isActiveTextYes = true,
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <Div {...props}>
        <div className="top-table">
          <div className="icon-content">
            <img src={icon} />
          </div>
          <div className="close-modal-content">
            <IconButton className="bt-close" src={iconClose} onClick={onClose} />
          </div>
        </div>
        
        <div className="dialog-content">
          <div className="content">
            <div className="title">{title}</div>
            {subTitle && <div className="description">{subTitle}</div>}
            {nameItem && <div className="name-item">{nameItem}</div>}
            <div className="description">{description}</div>
            <div className="description">{html}</div>
          </div>
        </div>
        <div className="dialog-button">
          <ButtonSecondary onClick={onNo}>{textNo || 'No'}</ButtonSecondary>
          <Button disabled={!isActiveTextYes} onClick={onSubmit}>{textYes || 'Yes'}</Button>
        </div>
      </Div>
    </Modal>
  )
}

DialogConfirmV2.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  nameItem: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  onSubmit: PropTypes.func,
  subTitle: PropTypes.string,
  onNo: PropTypes.any,
  html: PropTypes.any,
}

export default DialogConfirmV2
